import type { BaseQueryFn } from '@reduxjs/toolkit/query';
// axios
import type { AxiosRequestConfig, AxiosError, AxiosRequestHeaders } from 'axios';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
/**
 *Due to the Same-Origin Policy and the withCredentials=true setting, it signals to the browser that it wants to include cookies associated with the domain in the request headers.
 *And the browser automatically includes the session cookie associated with the domain in the request headers.
 */

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      headers?: AxiosRequestHeaders;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      responseType?: AxiosRequestConfig['responseType'];
    },
    unknown,
    unknown
  > =>
  async ({ url, headers, method, data, params, responseType }) => {
    try {
      // console.log('Endpoint url:', method?.toUpperCase(), baseUrl + url);
      // console.log('Request body:', data);
      const result = await axios({
        url: baseUrl + url,
        headers: headers ? headers : {},
        withCredentials:
          window.location.hostname === 'localhost' && window.location.port === '3000'
            ? false
            : true,
        method,
        data,
        params,
        responseType,
      });
      // console.log('Result:', result);

      return {
        data: result.data,
      };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      const error = {
        status: err.response?.status,
        data: err.response?.data || err.message,
      };
      // console.log('Error:', error);
      return {
        error: error,
      };
    }
  };
