import { ReactNode } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fCapitalizeFirstLetter, fLongNumber } from 'src/utils/format';
// components
import Indicator from 'src/components/gravity/Indicator';
import Resource from 'src/components/gravity/Resource';
import { TextCustomize } from 'src/components/gravity/text';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description: string;
  status?: {
    uptime: 'alert' | 'info' | 'success' | 'warning'; // down - unknown - up - warning
    state: string; // migrating - restoring - active
  };
  resources?: {
    usage: number;
    limit: number;
    isInode?: boolean;
    action?: ReactNode;
  };
};

// ----------------------------------------------------------------------

export default function StatusResourcesCard({ title, description, status, resources }: Props) {
  // HOOK
  const { translate, currentLang } = useLocales();

  return (
    <div className="gv-card gv-flex-1" style={{ backgroundColor: 'var(--color-surface-bright)' }}>
      <div className="gv-card-content">
        <h3 className="gv-card-title">{translate(title)}</h3>

        {status && (
          <Indicator type={status.uptime} text={fCapitalizeFirstLetter(translate(status.state))} />
        )}

        {resources && (
          <Resource
            amount={translate(
              resources.isInode
                ? 'wpone.sites.details.statistics.resources.infoCard.resourceDescription.fileLimit'
                : 'wpone.sites.details.overview.statusResources.resourcesDescription',
              {
                used: resources.isInode
                  ? fLongNumber(resources.usage, currentLang.value)
                  : resources.usage,
                limit: resources.isInode
                  ? fLongNumber(resources.limit, currentLang.value)
                  : resources.limit === Number.MAX_VALUE
                  ? '∞'
                  : resources.limit,
              }
            )}
            currentVal={resources.usage}
            maxVal={resources.limit}
            defaultThresholds
            style={{
              marginTop: '16px',
            }}
          />
        )}

        <TextCustomize textClass="gv-text-on-alternative" text={translate(description)} />
      </div>

      {resources?.action && <div className="gv-card-footer">{resources.action}</div>}
    </div>
  );
}
