// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { toggleSubScoreDescription } from 'src/redux/features/site';
// @types
import { LighthousePerformanceSubScoreEnum, LighthouseStatusEnum } from 'src/@types/site';
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import {
  convertLighthouseStatsDisplay,
  convertLighthouseValueToStatus,
  convertLighthouseStatusToColorCode,
  getLighthouseStats,
} from 'src/utils/convert';
// components
import { Toggle } from 'src/components/gravity/form';
//
import SubScoreCard from './SubScoreCard';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function PerformanceSubScores({ site }: Props) {
  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const isUpDesktop = useResponsive('up', 'desktop_max');

  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const { showSubScoreDescription } = useSelector((state) => state.site);

  // HELPER FUNCTION
  function getPerformanceSubscoreInfoDisplay(
    subScore: LighthousePerformanceSubScoreEnum,
    currentValue: number | undefined,
    previousValue: number | undefined
  ) {
    const status = convertLighthouseValueToStatus(subScore, currentValue);
    const colorCode = convertLighthouseStatusToColorCode(status);
    const { convertedCurrentValueDisplay, valueDiffDisplay, isEqual } =
      convertLighthouseStatsDisplay(subScore, previousValue, currentValue);

    return {
      colorCode,
      title: `dashboard.sites.details.mwp.performance.block.performance.subScores.cardInfo.${subScore}.title`,
      description: `dashboard.sites.details.mwp.performance.block.performance.subScores.cardInfo.${subScore}.description`,
      convertedCurrentValueDisplay,
      valueDiffDisplay: isEqual
        ? translate(
            'dashboard.sites.details.mwp.performance.block.performance.subScores.cardInfo.valueMessage.same'
          )
        : translate(
            'dashboard.sites.details.mwp.performance.block.performance.subScores.cardInfo.valueMessage.diff',
            { valueDiffDisplay: valueDiffDisplay }
          ),
    };
  }

  return (
    <div className="gv-flex-column-md">
      <div
        className={`gv-flex-column-sm gv-tab-flex-row ${
          isMobile ? 'gv-items-start' : 'gv-items-center'
        }`}
      >
        <p className="gv-text-lg gv-text-bold">
          {translate('dashboard.sites.details.mwp.performance.block.performance.subScores.title')}
        </p>
        <Toggle
          label={translate(
            'dashboard.sites.details.mwp.performance.block.performance.subScores.toggleDescriptionLabel'
          )}
          labelId="sub-score-filter"
          condensed
          checked={showSubScoreDescription}
          onChange={() => dispatch(toggleSubScoreDescription())}
        />
      </div>

      {showSubScoreDescription && (
        <>
          <div className="gv-flex-column-sm gv-tab-flex-row gv-tab-gap-md">
            {[
              LighthouseStatusEnum.unknown,
              LighthouseStatusEnum.alert,
              LighthouseStatusEnum.warning,
              LighthouseStatusEnum.good,
            ]
              .map((performanceValue) => ({
                status: performanceValue,
                ...convertLighthouseStatusToColorCode(performanceValue),
              }))
              .map((performanceInfo) => (
                <div
                  key={performanceInfo.status}
                  className="gv-flex gv-flex-row"
                  style={{ gap: '4px' }}
                >
                  <span style={{ color: `var(${performanceInfo.color})` }}>
                    <gv-icon src={`/src/icons/${performanceInfo.iconName}.svg`} />
                  </span>
                  <p className="gv-text-sm">
                    {translate(
                      `dashboard.sites.details.mwp.performance.block.performance.subScores.colorCodes.${performanceInfo.status}`
                    )}
                  </p>
                </div>
              ))}
          </div>
          <div className="gv-divider gv-divider-light" />
        </>
      )}

      <div
        className={`gv-grid gv-grid-cols-${isUpDesktop ? 3 : isMobile ? 1 : 2}`}
        style={{
          gap: '16px 24px',
        }}
      >
        {[
          LighthousePerformanceSubScoreEnum.FCP,
          LighthousePerformanceSubScoreEnum.LCP,
          LighthousePerformanceSubScoreEnum.BLOCKING,
          LighthousePerformanceSubScoreEnum.CLS,
          LighthousePerformanceSubScoreEnum.SPEED_INDEX,
        ]
          .map((subScore) => {
            const stat = getLighthouseStats(subScore, site.lighthouse_statistics);

            return getPerformanceSubscoreInfoDisplay(
              subScore,
              stat.currentValue,
              stat.previousValue
            );
          })
          .map((card, index) => (
            <SubScoreCard
              key={index}
              colorCode={card.colorCode}
              title={card.title}
              description={card.description}
              convertedCurrentValueDisplay={card.convertedCurrentValueDisplay}
              valueDiffDisplay={card.valueDiffDisplay}
            />
          ))}
      </div>
    </div>
  );
}
