// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { MWPSiteStatusEnum, SiteStatusEnum, UptimeStatusEnum } from 'src/@types/site';

// ----------------------------------------------------------------------

export function getMwpSiteStatus(
  siteData: ListSiteResponseDTO | undefined
): MWPSiteStatusEnum | undefined {
  if (!siteData) return undefined;

  const { state, uptime } = siteData;

  // ADDING INCOMPLETE AND MIGRATING STATUS LATER
  return state === SiteStatusEnum.suspended
    ? MWPSiteStatusEnum.SUSPENDED
    : uptime === UptimeStatusEnum.up
    ? MWPSiteStatusEnum.UP
    : uptime === UptimeStatusEnum.down
    ? MWPSiteStatusEnum.DOWN
    : undefined;
}
