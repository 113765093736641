// @types
import {
  UpdateDetails,
  PerformedUpdatesResponse,
  KeyUpdateDetailsExample,
} from '@joonasvanhatapio/wp-cloud-backend-types';
import { UpdateDetailsTypeEnum, UpdateReportStats, UpdateReportStatusEnum } from 'src/@types/site';

// ----------------------------------------------------------------------

export function getUpdateReportStats(
  // Get these data directly from API
  updated_wp: UpdateDetails | null,
  updated_themes: KeyUpdateDetailsExample | null,
  updated_plugins: KeyUpdateDetailsExample | null
) {
  // There can be cases where updated_wp, updated_themes, updated_plugins is empty obj (which can be considered as null)
  const wpUpdate = !updated_wp || Object.keys(updated_wp).length === 0 ? null : updated_wp;
  const themesUpdates =
    !updated_themes || Object.keys(updated_themes).length === 0 ? null : updated_themes;
  const pluginsUpdates =
    !updated_plugins || Object.keys(updated_plugins).length === 0 ? null : updated_plugins;

  // Check wp update
  let wpUpdateDetails: boolean | undefined = undefined; // Undefined: none - True: success - False: failed
  if (wpUpdate) {
    if (wpUpdate.reverted) {
      wpUpdateDetails = false;
    } else {
      wpUpdateDetails = true;
    }
  }

  // Check themes updates
  let themesUpdatesDetails = {
    attempted: 0,
    success: 0,
  };
  if (themesUpdates) {
    themesUpdatesDetails = {
      attempted: Object.values(themesUpdates).length,
      success: Object.values(themesUpdates).filter((update) => update.reverted !== true).length,
    };
  }

  // Check plugins updates
  let pluginsUpdatesDetails = {
    attempted: 0,
    success: 0,
  };
  if (pluginsUpdates) {
    pluginsUpdatesDetails = {
      attempted: Object.values(pluginsUpdates).length,
      success: Object.values(pluginsUpdates).filter((update) => update.reverted !== true).length,
    };
  }

  return {
    wp: wpUpdateDetails,
    themes: themesUpdatesDetails,
    plugins: pluginsUpdatesDetails,
  };
}

export function getUpdateReportStatus(reportStats: UpdateReportStats): UpdateReportStatusEnum {
  // Update details
  // CORE
  const wpUpdateDetails = reportStats.wp;
  const noWpUpdated = wpUpdateDetails === undefined;
  const wpUpdatedSuccess = wpUpdateDetails === true;
  const wpUpdatedFailed = wpUpdateDetails === false;
  // Themes
  const themesUpdatesDetails = reportStats.themes;
  const noThemesUpdated = themesUpdatesDetails.attempted === 0;
  const themesUpdatedFailed = !noThemesUpdated && themesUpdatesDetails.success === 0; // All failed
  const themesUpdatedSuccess =
    !noThemesUpdated && themesUpdatesDetails.success === themesUpdatesDetails.attempted; // All success
  // Plugins
  const pluginsUpdatesDetails = reportStats.plugins;
  const noPluginsUpdated = pluginsUpdatesDetails.attempted === 0;
  const pluginsUpdatedFailed = !noPluginsUpdated && pluginsUpdatesDetails.success === 0; // All failed
  const pluginsUpdatedSuccess =
    !noPluginsUpdated && pluginsUpdatesDetails.success === pluginsUpdatesDetails.attempted; // All success

  // Row details
  // NONE state (No updates)
  if (noWpUpdated && noThemesUpdated && noPluginsUpdated) {
    return UpdateReportStatusEnum.NONE;
  }
  // FAILED state (There is at least 1 update but all are failed)
  // Each update type will be checked if there is no update or they are all failed (None state has been checked previously)
  else if (
    (noWpUpdated || wpUpdatedFailed) &&
    (noThemesUpdated || themesUpdatedFailed) &&
    (noPluginsUpdated || pluginsUpdatedFailed)
  ) {
    return UpdateReportStatusEnum.FAILED;
  } else {
    // GOOD state
    if (
      (noWpUpdated || wpUpdatedSuccess) &&
      (noThemesUpdated || themesUpdatedSuccess) &&
      (noPluginsUpdated || pluginsUpdatedSuccess)
    )
      return UpdateReportStatusEnum.GOOD;
    // WARNING state
    else {
      return UpdateReportStatusEnum.WARNING;
    }
  }
}

export function getUpdateReport(
  reportId: string,
  reportList: PerformedUpdatesResponse[] | undefined
) {
  return reportList === undefined ? undefined : reportList.find((report) => report.id === reportId);
}

export function getUpdateReportDetailsArr(updateReport: PerformedUpdatesResponse | undefined) {
  // There can be cases where updated_wp, updated_themes, updated_plugins is empty obj (which can be considered as null)
  const wpUpdate =
    !updateReport?.updated_wp || Object.keys(updateReport.updated_wp).length === 0
      ? null
      : updateReport.updated_wp;
  const themesUpdates =
    !updateReport?.updated_themes || Object.keys(updateReport.updated_themes).length === 0
      ? null
      : updateReport.updated_themes;
  const pluginsUpdates =
    !updateReport?.updated_plugins || Object.keys(updateReport.updated_plugins).length === 0
      ? null
      : updateReport.updated_plugins;

  const wpUpdatesArr: UpdateDetails[] = !wpUpdate ? [] : [wpUpdate];
  const themesUpdatesArr: UpdateDetails[] = !themesUpdates ? [] : Object.values(themesUpdates);
  const pluginsUpdatesArr: UpdateDetails[] = !pluginsUpdates ? [] : Object.values(pluginsUpdates);

  return [
    ...wpUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.CORE, details: update })),
    ...themesUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.THEME, details: update })),
    ...pluginsUpdatesArr.map((update) => ({
      type: UpdateDetailsTypeEnum.PLUGIN,
      details: update,
    })),
  ];
}

export function getUpdateDetails(
  detailsName: string, // Type included
  updateReport: PerformedUpdatesResponse | undefined
) {
  const detailsArr = getUpdateReportDetailsArr(updateReport);

  return detailsArr.find(
    (detailsInfo) =>
      `${detailsInfo.type}${
        detailsInfo.details.title
          ? `-${detailsInfo.details.title.toLowerCase().split(' ').join('-')}`
          : ''
      }` === detailsName
  );
}
