import { useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import * as Yup from 'yup';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// redux
import { useDispatch } from 'src/redux/store';
import { setDeletedSiteName } from 'src/redux/features/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';
import { TextCustomize } from 'src/components/gravity/text';

// ----------------------------------------------------------------------

const PasswordInputStyle = styled.div`
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
`;

enum mwpInstallationVersionEnum {
  V2 = 'v2',
  V3 = 'v3',
}

type FormValuesProps = {
  password: string;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteForm({ onClose }: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  // VAR
  const installation_version = mwpInstallationVersionEnum.V2;
  const dummy_password = '1234';

  // FORM
  const FormSchema = Yup.object().shape({
    password: Yup.string()
      .required(
        translate(
          'dashboard.sites.details.mwp.settings.deleteSiteDialog.inputField.password.validation1'
        )
      )
      .test(
        'password',
        translate(
          'dashboard.sites.details.mwp.settings.deleteSiteDialog.inputField.password.validation2'
        ),
        function (value) {
          if (value === dummy_password) {
            return true;
          }
          return false;
        }
      ),
  });

  const defaultValues = {
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async () => {
    if (!name) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'mwp-delete-site',
    });

    setIsLoading(true);

    try {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve('Fetch successful');
        }, 3000);
      });

      const isSuccess = true;

      if (isSuccess) {
        dispatch(setDeletedSiteName(name));
        navigate('/sites/my-sites');
      } else {
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(
              'dashboard.sites.details.mwp.settings.deleteSiteDialog.toast.error',
              { domain: name }
            ),
          });
        } else {
          displayToast(
            translate('dashboard.sites.details.mwp.settings.deleteSiteDialog.toast.error', {
              domain: name,
            }),
            { variant: 'alert' }
          );
        }
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('dashboard.sites.details.mwp.settings.deleteSiteDialog.title')}
        </h2>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm">
            <TextCustomize
              text={translate(
                `dashboard.sites.details.mwp.settings.deleteSiteDialog.description.${installation_version}`,
                {
                  domain: name,
                }
              )}
            />

            <p>
              {translate('dashboard.sites.details.mwp.settings.deleteSiteDialog.confirmation', {
                control_panel_name: translate('dashboard.general.controlPanel.one'),
              })}
            </p>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}
            <PasswordInputStyle>
              <RHFTextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                label={translate(
                  'dashboard.sites.details.mwp.settings.deleteSiteDialog.inputField.password.label',
                  { control_panel_name: translate('dashboard.general.controlPanel.one') }
                )}
                labelId="password"
                addonSuffix={
                  <button
                    type="button"
                    className="gv-addon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <gv-icon
                      src={`/src/icons/${showPassword ? 'visibility_off' : 'visibility'}.svg`}
                    />
                  </button>
                }
              />
            </PasswordInputStyle>
          </div>
        </FormProvider>
      </div>

      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          uiType="destructive"
          text={translate('dashboard.sites.details.mwp.settings.deleteSiteDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
