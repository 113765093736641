// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeleteSiteForm from './DeleteSiteForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteDialog({ open, onClose }: Props) {
  return (
    <ActionDialog open={open} onClose={onClose} children={<DeleteSiteForm onClose={onClose} />} />
  );
}
