import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
// @types
import {
  CommunityCardContent,
  SupportMobileOS,
  WPCommunityEventResponse,
  WPCommunityNewsResponse,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { getUserTimezone } from 'src/utils/getTimeZones';
// components
import { PageSection } from 'src/components/gravity/page';
//
import RecommendedCard from './RecommendedCard';

// ----------------------------------------------------------------------

type Props = {
  onOpenCompanianAppDownloadDialog: (selectedOS: SupportMobileOS) => void;
};

// ----------------------------------------------------------------------

export default function RecommendedBlock({ onOpenCompanianAppDownloadDialog }: Props) {
  // HOOKS
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <PageSection title={translate('dashboard.sites.details.mwp.overview.recommended.title')}>
      <div className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-gap-lg`}>
        <CompanianCard onOpenCompanianAppDownloadDialog={onOpenCompanianAppDownloadDialog} />
        <CommunityCard />
      </div>
    </PageSection>
  );
}

// ----------------------------------------------------------------------

type CompanianCardProps = {
  onOpenCompanianAppDownloadDialog: (selectedOS: SupportMobileOS) => void;
};

// ----------------------------------------------------------------------

function CompanianCard({ onOpenCompanianAppDownloadDialog }: CompanianCardProps) {
  // HOOKS
  const isDesktop = useResponsive('up', 'desktop_min');

  // STATE
  const [userOS, setUserOS] = useState<SupportMobileOS | null>(null);

  // VAR
  const ctaButtons =
    userOS && !isDesktop
      ? [
          {
            text: 'dashboard.sites.details.mwp.overview.recommended.companion.action.autoDetect',
            onClick: () => {
              const url =
                userOS === SupportMobileOS.ios
                  ? 'https://apps.apple.com/ua/app/one-com-companion/id1522498353'
                  : 'https://play.google.com/store/apps/details?id=com.dotcompanionapp';
              window.open(url, '_blank');
            },
          },
        ]
      : Object.values(SupportMobileOS).map((os) => ({
          text: `dashboard.sites.details.mwp.overview.recommended.companion.action.${os}`,
          onClick: () => onOpenCompanianAppDownloadDialog(os),
        }));

  //
  useEffect(() => {
    function detectUserOs() {
      const { userAgent } = navigator;
      if (/iPad|iPhone|iPod/.test(userAgent)) return SupportMobileOS.ios;
      if (/Android/.test(userAgent)) return SupportMobileOS.android;
      return null;
    }

    setUserOS(detectUserOs());
  }, []);

  return (
    <RecommendedCard
      title={'dashboard.sites.details.mwp.overview.recommended.companion.title'}
      description={'dashboard.sites.details.mwp.overview.recommended.companion.description'}
      ctaBtns={ctaButtons}
      isCompanionCard
    />
  );
}

// ----------------------------------------------------------------------

function CommunityCard() {
  // HOOK
  const { translate, currentLang } = useLocales();

  const isMountedRef = useIsMountedRef();

  // STATE
  const [cardInfo, setCardInfo] = useState<CommunityCardContent | undefined>(undefined);

  // HELPER FUNCTION
  async function getRandomCommunityCardInfo() {
    // Get events info
    try {
      const userTimezone = getUserTimezone();

      const wpEventResponse: AxiosResponse<WPCommunityEventResponse> = await axios
        .create({
          baseURL: 'https://api.wordpress.org',
        })
        .get(`/events/1.0/?location=${userTimezone.split('/')[1]}`);

      if (!wpEventResponse.data) {
        throw new Error();
      }

      if (wpEventResponse.data.events.length > 0) {
        const eventInfo =
          wpEventResponse.data.events[
            Math.floor(Math.random() * wpEventResponse.data.events.length)
          ];

        const startDate = new Date(eventInfo.date);
        const endDate = new Date(eventInfo.end_date);

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();
        const month = startDate.toLocaleString(currentLang.value, { month: 'long' });
        const year = startDate.getFullYear();

        return {
          title: eventInfo.title,
          subtitle: `${
            startDay === endDay ? startDay : `${startDay}-${endDay}`
          } ${month}, ${year}. ${eventInfo.location.location}`,
          ctaBtn: {
            text: translate(
              'dashboard.sites.details.mwp.overview.recommended.community.event.action'
            ),
            onClick: () => window.open(eventInfo.url, '_blank'),
          },
        };
      }
    } catch (error) {
      console.log(error);
    }

    // Get news info
    try {
      const wpNewsResponse: AxiosResponse<WPCommunityNewsResponse> = await axios
        .create({
          baseURL: 'https://api.rss2json.com',
        })
        .get(`/v1/api.json?rss_url=https://wordpress.org/news/feed/`);

      if (!wpNewsResponse.data) {
        throw new Error();
      }

      if (wpNewsResponse.data.items.length > 0) {
        const newsInfo =
          wpNewsResponse.data.items[Math.floor(Math.random() * wpNewsResponse.data.items.length)];

        return {
          title: newsInfo.title,
          subtitle: newsInfo.author,
          description: newsInfo.description,
          ctaBtn: {
            text: translate(
              'dashboard.sites.details.mwp.overview.recommended.community.news.action'
            ),
            onClick: () => window.open(newsInfo.link, '_blank'),
          },
        };
      }
    } catch (error) {
      console.log(error);
    }

    // Get static info
    const randomNumber: number = Math.floor(Math.random() * 3);
    return {
      title: `dashboard.sites.details.mwp.overview.recommended.community.static.info${randomNumber}.title`,
      description: `dashboard.sites.details.mwp.overview.recommended.community.static.info${randomNumber}.description`,
      ctaBtn: {
        text: `dashboard.sites.details.mwp.overview.recommended.community.static.info${randomNumber}.action`,
        onClick: () =>
          window.open(
            randomNumber === 1
              ? 'https://events.wordpress.org'
              : randomNumber === 2
              ? 'https://learn.wordpress.org'
              : 'https://wordpress.org/documentation',
            '_blank'
          ),
      },
    };
  }

  //
  useEffect(() => {
    getRandomCommunityCardInfo().then((info) => {
      if (isMountedRef.current) {
        setCardInfo(info);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cardInfo ? (
    <RecommendedCard
      title={cardInfo.title}
      description={cardInfo.description}
      subtitle={cardInfo.subtitle}
      ctaBtns={[cardInfo.ctaBtn]}
    />
  ) : (
    <></>
  );
}
