// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// components
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  subtitle?: string;
  description?: string;
  ctaBtns: {
    text: string;
    onClick: VoidFunction;
  }[];
  isCompanionCard?: boolean;
};

// ----------------------------------------------------------------------

export default function RecommendedCard({
  title,
  subtitle,
  description,
  ctaBtns,
  isCompanionCard,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const isTablet = useResponsive('down', 'tablet_max');

  return (
    <div className="gv-card" style={{ background: 'var(--color-surface-bright)' }}>
      <div className="gv-card-content">
        <div className="gv-flex-column-md">
          <gv-tile
            src={`/src/tiles/${isCompanionCard ? 'mobile' : 'wordpress-v1'}.svg`}
            size="sm"
          />
          <div className="gv-flex gv-flex-col">
            <p className="gv-text-sm gv-text-bold">{translate(title)}</p>
            {subtitle && <p className="gv-text-sm ">{translate(subtitle)}</p>}
            {description && (
              <p className="gv-text-sm gv-text-on-alternative">{translate(description)}</p>
            )}
          </div>
        </div>
      </div>
      <div className={`gv-card-footer ${isTablet ? 'gv-flex-column-md' : 'gv-flex-row-md'}`}>
        {ctaBtns.map((button) => (
          <Button
            key={button.text}
            endIcon={isCompanionCard ? undefined : 'open_in_new'}
            text={translate(button.text)}
            uiType="secondary"
            condensed
            onClick={button.onClick}
            extraClass="gv-w-full"
          />
        ))}
      </div>
    </div>
  );
}
