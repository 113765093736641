// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { LighthouseScoreEnum } from 'src/@types/site';

// ----------------------------------------------------------------------

export default function getWpRocketAndRankMathStatus(
  site: ListSiteResponseDTO,
  cardType: LighthouseScoreEnum
) {
  const isWpRocketEnable = site.wordpress_details.plugins
    .map((plugin) => plugin.name)
    .includes('wp-rocket');
  const isRankMathEnable = site.wordpress_details.plugins
    .map((plugin) => plugin.name)
    .includes('rankmath');

  if (
    (cardType === LighthouseScoreEnum.PERFORMANCE && isWpRocketEnable) ||
    (cardType === LighthouseScoreEnum.SEO && isRankMathEnable)
  ) {
    return true; // Enabled
  }
  return false; // Disabled
}
