import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
// @types
import { PerformedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
import { UpdateReportStats, UpdateReportStatusEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime } from 'src/utils/format';
import { getUpdateReportStats, getUpdateReportStatus } from 'src/utils/getSiteUpdateInfo';
// components
import Indicator from 'src/components/gravity/Indicator';

// ----------------------------------------------------------------------

type Props = {
  row: PerformedUpdatesResponse;
};

// ----------------------------------------------------------------------

export default function UpdateTableRow({ row }: Props) {
  const navigate = useNavigate();

  const { name, cluster, namespace } = useParams();

  // HOOK
  const { translate, currentLang } = useLocales();

  // STATE
  const [reportStats, setReportStats] = useState<UpdateReportStats>({
    wp: undefined,
    themes: {
      attempted: 0,
      success: 0,
    },
    plugins: {
      attempted: 0,
      success: 0,
    },
  });

  // VAR
  const { update_attempted_on, updated_wp, updated_themes, updated_plugins } = row;

  const rowState = getRowState();

  // There can be cases where updated_wp, updated_themes, updated_plugins is empty obj (which can be considered as null)
  const wpUpdate = !updated_wp || Object.keys(updated_wp).length === 0 ? null : updated_wp;
  const themesUpdates =
    !updated_themes || Object.keys(updated_themes).length === 0 ? null : updated_themes;
  const pluginsUpdates =
    !updated_plugins || Object.keys(updated_plugins).length === 0 ? null : updated_plugins;

  // HELPER FUNCTION
  function getRowState(): {
    status: UpdateReportStatusEnum;
    message: string;
  } {
    const rowStatus = getUpdateReportStatus(reportStats);

    // Row details
    // NONE state (No updates)
    if (rowStatus === UpdateReportStatusEnum.NONE) {
      return {
        status: rowStatus,
        message: translate(
          'dashboard.sites.details.mwp.updates.updateListing.table.row.status.none.message'
        ),
      };
    }
    // FAILED state (There is at least 1 update but all are failed)
    // Each update type will be checked if there is no update or they are all failed (None state has been checked previously)
    else if (rowStatus === UpdateReportStatusEnum.FAILED) {
      return {
        status: rowStatus,
        message: translate(
          'dashboard.sites.details.mwp.updates.updateListing.table.row.status.failed.message'
        ),
      };
    } else {
      // GOOD state
      if (rowStatus === UpdateReportStatusEnum.GOOD) {
        const attemptedThemesNumber = reportStats.themes.attempted;
        const successThemesNumber = reportStats.themes.success;
        const attemptedPluginsNumber = reportStats.plugins.attempted;
        const successPluginsNumber = reportStats.plugins.success;

        let message: string = '';

        // 1. Only core success
        if (
          reportStats.wp !== undefined &&
          attemptedThemesNumber === 0 &&
          attemptedPluginsNumber === 0
        ) {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.core';
        }
        // 2. Only themes success
        else if (
          reportStats.wp === undefined &&
          attemptedThemesNumber !== 0 &&
          attemptedPluginsNumber === 0
        ) {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.themes';
        }
        // 3. Only plugins success
        else if (
          reportStats.wp === undefined &&
          attemptedThemesNumber === 0 &&
          attemptedPluginsNumber !== 0
        ) {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.plugins';
        }
        // 4. Only core and themes success
        else if (
          reportStats.wp !== undefined &&
          attemptedThemesNumber !== 0 &&
          attemptedPluginsNumber === 0
        ) {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.coreThemes';
        }
        // 5. Only core and plugins success
        else if (
          reportStats.wp !== undefined &&
          attemptedThemesNumber === 0 &&
          attemptedPluginsNumber !== 0
        ) {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.corePlugins';
        }
        // 6. Only themes and plugins success
        else if (
          reportStats.wp === undefined &&
          attemptedThemesNumber !== 0 &&
          attemptedPluginsNumber !== 0
        ) {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.themesPlugins';
        }
        // 7. All three success
        else {
          message =
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.good.message.all';
        }

        return {
          status: rowStatus,
          message: translate(message, {
            successThemes: successThemesNumber,
            attemptedThemes: attemptedThemesNumber,
            successPlugins: successPluginsNumber,
            attemptedPlugins: attemptedPluginsNumber,
          }),
        };
      }
      // WARNING state
      else {
        return {
          status: rowStatus,
          message: translate(
            'dashboard.sites.details.mwp.updates.updateListing.table.row.status.warning.message'
          ),
        };
      }
    }
  }

  //
  useEffect(() => {
    setReportStats(getUpdateReportStats(wpUpdate, themesUpdates, pluginsUpdates));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tr>
      <td>{fShortDateTime(new Date(update_attempted_on), { locale: currentLang.value })}</td>
      <td>
        <Indicator
          type={
            rowState.status === UpdateReportStatusEnum.NONE
              ? 'default'
              : rowState.status === UpdateReportStatusEnum.GOOD
              ? 'success'
              : rowState.status === UpdateReportStatusEnum.WARNING
              ? 'warning'
              : 'alert'
          }
          text={translate(
            `dashboard.sites.details.mwp.updates.updateListing.table.row.status.${rowState.status}.label`
          )}
        />
      </td>
      <td>{translate(rowState.message)}</td>
      <td>
        {(wpUpdate || themesUpdates || pluginsUpdates) && (
          <a
            href="/#"
            className="gv-action gv-action-primary"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/sites/updates/${cluster}/${namespace}/${name}/${row.id}`);
            }}
            style={{ color: 'var(--color-state-form-on)' }}
          >
            <span className="gv-text-sm gv-text-bold">
              {translate('dashboard.sites.details.mwp.updates.updateListing.table.row.action')}
            </span>
            <gv-icon src="/src/icons/arrow_forward.svg" />
          </a>
        )}
      </td>
    </tr>
  );
}
