// @types
import { MWPAdvancedLoginProtectionStatusEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import AdministrationOption from '../../settings/AdministrationOption';

// ----------------------------------------------------------------------

type Props = {
  onOpenAdvancedLoginProtectionDialog: VoidFunction;
  onOpenUpdateWPCoreDialog: VoidFunction;
  onOpenDeleteSiteDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function MWPAdministrationBlock({
  onOpenAdvancedLoginProtectionDialog,
  onOpenUpdateWPCoreDialog,
  onOpenDeleteSiteDialog,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const administrationOptionDisplayInfo: {
    iconName: string;
    title: string;
    description: string;
    currentAlpStatus?: MWPAdvancedLoginProtectionStatusEnum;
    openInNewTab?: boolean;
    onClick: VoidFunction;
  }[] = [
    {
      iconName: 'terminal',
      title: 'dashboard.sites.details.mwp.settings.siteAdministration.ssh.title',
      description: 'dashboard.sites.details.mwp.settings.siteAdministration.ssh.description',
      openInNewTab: true,
      onClick: () =>
        window.open('https://www.one.com/admin/external-access-administration.do', '_blank'),
    },
    {
      iconName: 'database',
      title: 'dashboard.sites.details.mwp.settings.siteAdministration.database.title',
      description: 'dashboard.sites.details.mwp.settings.siteAdministration.database.description',
      openInNewTab: true,
      onClick: () => window.open('https://www.one.com/admin/mysqlphp.do', '_blank'),
    },
    {
      iconName: 'preview',
      title: 'dashboard.sites.details.mwp.settings.siteAdministration.loginProtection.title',
      description:
        'dashboard.sites.details.mwp.settings.siteAdministration.loginProtection.description',
      currentAlpStatus: MWPAdvancedLoginProtectionStatusEnum.ACTIVE, // Dummy data for AdvancedLoginProtection
      onClick: onOpenAdvancedLoginProtectionDialog,
    },
    {
      iconName: 'sync',
      title: 'dashboard.sites.details.mwp.settings.siteAdministration.updateCore.title',
      description: 'dashboard.sites.details.mwp.settings.siteAdministration.updateCore.description',
      onClick: onOpenUpdateWPCoreDialog,
    },
    {
      iconName: 'delete',
      title: 'dashboard.sites.details.mwp.settings.siteAdministration.delete.title',
      description: 'dashboard.sites.details.mwp.settings.siteAdministration.delete.description',
      onClick: onOpenDeleteSiteDialog,
    },
  ];

  return (
    <div className="gv-flex-column-md">
      <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
        <p className="gv-text-lg gv-text-bold">
          {translate('dashboard.sites.details.mwp.settings.siteAdministration.title')}
        </p>
        <p className="gv-text-sm">
          {translate('dashboard.sites.details.mwp.settings.siteAdministration.description')}
        </p>
      </div>

      <div className="gv-grid gv-gap-md gv-grid-cols-2">
        {administrationOptionDisplayInfo
          // New user who has not enabled AdvancedLoginProtection hide its AdministrationOption
          // Old user who has enabled AdvancedLoginProtection display between active and inactive status
          .filter(
            (option) => option.iconName !== 'preview' || option.currentAlpStatus !== undefined
          )
          .map((option, index) => (
            <AdministrationOption
              key={index}
              iconName={option.iconName}
              title={option.title}
              description={translate(option.description, {
                control_panel_name: translate('dashboard.general.controlPanel.one'),
              })}
              currentAlpStatus={option.currentAlpStatus}
              openInNewTab={option.openInNewTab}
              onClick={option.onClick}
            />
          ))}
      </div>
    </div>
  );
}
