import { createApi } from '@reduxjs/toolkit/query/react';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------
// MWP Site details - Overview tab - Preview section

export const screenshotApi = createApi({
  reducerPath: 'screenshotApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/screenshot' }),
  tagTypes: ['Screenshot'],
  endpoints: (builder) => ({
    // GET SITE PREVIEW SCREENSHOT
    getScreenshot: builder.query<any, { cluster: string; namespace: string }>({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}`,
        method: 'get',
        responseType: 'arraybuffer',
      }),
    }),
  }),
});

export const { useLazyGetScreenshotQuery } = screenshotApi;
