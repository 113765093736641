import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useLazyGetScreenshotQuery } from 'src/redux/api/screenshotApi';
import { useTriggerUpdatesMutation } from 'src/redux/api/updateApi';
// @types
import { MWPSiteStatusEnum, UpdateReportStatusEnum } from 'src/@types/site';
import { CompletedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
import { convertStatusCode } from 'src/utils/convert';
import { fShortDateTime } from 'src/utils/format';
import { getUpdateReportStats, getUpdateReportStatus } from 'src/utils/getSiteUpdateInfo';
// components
import Button from 'src/components/gravity/Button';
import Indicator from 'src/components/gravity/Indicator';

// ----------------------------------------------------------------------

enum UpdateDateInfoTypeEnum {
  NEXT = 'next',
  LAST = 'last',
}

type LastUpdateInfoLayout = {
  indicatorType: 'alert' | 'success' | 'warning' | 'default';
  description: string;
  btn?: {
    text: string;
    onClick: VoidFunction;
  };
};

type Props = {
  siteStatus: MWPSiteStatusEnum | undefined;
  updatesData: CompletedUpdatesResponse | undefined;
};

// ----------------------------------------------------------------------

export default function PreviewBlock({ siteStatus, updatesData }: Props) {
  const { cluster, namespace, name } = useParams();

  const navigate = useNavigate();

  //  HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // API
  const [requestScreenshot] = useLazyGetScreenshotQuery();
  const [triggerUpdates, { isLoading: isTriggerUpdatesLoading }] = useTriggerUpdatesMutation();

  // STATE
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [isImgLoading, setIsImgLoading] = useState<boolean>(false);

  // VAR
  const siteTitle: string = 'Site title'; // DUMMY DATA
  const siteAutoUpdateActivated: boolean = true; // DUMMY DATA

  const siteLastUpdateLayoutInfo: LastUpdateInfoLayout = getLastUpdateReportLayoutInfo();

  // EVENT FUNCTION
  function handleTriggerUpdate() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'trigger-update',
    });

    triggerUpdates(name as string)
      .unwrap()
      .then((data) => {
        if (data.error === false) {
          displayToast(
            translate(
              'wpone.sites.details.updates.updateListing.requestResponse.successMessage.runUpdate'
            )
          );
        } else {
          displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
            variant: 'alert',
          });
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      });
  }

  // HELPER FUNCTION
  function getLastUpdateReportLayoutInfo(): LastUpdateInfoLayout {
    // 1. No data retrieved
    if (!updatesData) {
      return {
        indicatorType: 'default',
        description: 'dashboard.sites.details.mwp.overview.preview.description.error',
      };
    }

    // 2. Site DOWN
    if (siteStatus === MWPSiteStatusEnum.DOWN) {
      return {
        indicatorType: 'alert',
        description: 'dashboard.sites.details.mwp.overview.preview.description.siteDown',
      };
    }

    // 3. Auto update deactivated
    if (!siteAutoUpdateActivated) {
      return {
        indicatorType: 'default',
        description:
          'dashboard.sites.details.mwp.overview.preview.description.siteUp.autoUpdateDeactivated',
        btn: {
          text: 'dashboard.sites.details.mwp.overview.preview.action.goToUpdates',
          onClick: () => navigate(`/sites/updates/${cluster}/${namespace}/${name}`),
        },
      };
    }

    // 4. Site UP - No last update
    if (!updatesData.updateList[0]) {
      return {
        indicatorType: 'default',
        description: 'dashboard.sites.details.mwp.overview.preview.description.siteUp.noLastUpdate',
        btn: {
          text: 'dashboard.sites.details.mwp.overview.preview.action.runUpdate',
          onClick: () => handleTriggerUpdate(),
        },
      };
    }

    // Extract last update info
    const lastUpdateInfo = updatesData.updateList[0];

    const { updated_wp, updated_themes, updated_plugins } = lastUpdateInfo;

    const lastUpdateReportStats = getUpdateReportStats(updated_wp, updated_themes, updated_plugins);

    const lastUpdateReportStatus = getUpdateReportStatus(lastUpdateReportStats);

    // 5. Site UP - No updates
    const isNoUpdate: boolean = lastUpdateReportStatus === UpdateReportStatusEnum.NONE;
    // 6. Site UP - Last update failed
    const isLastUpdateFailed: boolean = lastUpdateReportStatus === UpdateReportStatusEnum.FAILED;
    // 7. Site UP - Last update partially failed
    const isLastUpdatePartialFailed: boolean =
      lastUpdateReportStatus === UpdateReportStatusEnum.WARNING;
    // 8. Site UP - Last update success
    const isLastUpdateSuccess: boolean = lastUpdateReportStatus === UpdateReportStatusEnum.GOOD;

    return {
      indicatorType: isLastUpdateFailed ? 'warning' : 'success',
      description: `dashboard.sites.details.mwp.overview.preview.description.siteUp.${
        isNoUpdate
          ? 'noUpdate'
          : isLastUpdateFailed
          ? 'failed'
          : isLastUpdatePartialFailed
          ? 'partialFailed'
          : 'success'
      }`,
      btn: {
        text: `dashboard.sites.details.mwp.overview.preview.action.${
          isNoUpdate ? 'goToUpdates' : isLastUpdateSuccess ? 'runUpdate' : 'seeReport'
        }`,
        onClick: isNoUpdate
          ? () => navigate(`/sites/updates/${cluster}/${namespace}/${name}`)
          : isLastUpdateSuccess
          ? () => handleTriggerUpdate()
          : () => navigate(`/sites/updates/${cluster}/${namespace}/${name}/${lastUpdateInfo.id}`),
      },
    };
  }

  function getSitePreviewImg() {
    setIsImgLoading(true);
    requestScreenshot({
      namespace: namespace as string,
      cluster: cluster as string,
    })
      .unwrap()
      .then(async (res) => {
        const blob = new Blob([res], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setImgUrl(url);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsImgLoading(false);
      });
  }

  //
  useEffect(() => {
    getSitePreviewImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gv-content-container gv-p-lg gv-flex gv-flex-col gv-tab-flex-row gv-tab-items-center gv-gap-fluid">
      <div
        className="gv-flex-column-lg gv-flex-1"
        style={{
          minWidth: 0, // This is used to ensure that the flex item can shrink properly and not overflow its container.
        }}
      >
        <div className="gv-flex-column-sm">
          <p className="gv-heading-sm gv-text-truncate">{siteTitle}</p>
          <Indicator
            type={siteLastUpdateLayoutInfo.indicatorType}
            text={translate(siteLastUpdateLayoutInfo.description)}
            extraClass="gv-items-baseline"
            textStyles={{ whiteSpace: 'normal' }}
          />
        </div>

        <div className="gv-flex gv-flex-col gv-tab-lg-flex-row gv-gap-fluid">
          {Object.values(UpdateDateInfoTypeEnum).map((type) => (
            <UpdateDateInfo
              key={type}
              type={type}
              date={
                updatesData
                  ? new Date(
                      type === UpdateDateInfoTypeEnum.NEXT
                        ? updatesData.nextUpdate
                        : updatesData.lastUpdate
                    )
                  : undefined
              }
              isAutoUpdateActivated={siteAutoUpdateActivated}
              isNoUpdates={updatesData && updatesData.updateList.length > 0 ? true : false}
              isSiteDown={siteStatus === MWPSiteStatusEnum.DOWN}
            />
          ))}
        </div>
        <div>
          {siteLastUpdateLayoutInfo.btn && (
            <Button
              disabled={isTriggerUpdatesLoading}
              text={translate(siteLastUpdateLayoutInfo.btn.text)}
              extraClass={`${isMobile ? 'gv-w-full' : ''}`}
              onClick={siteLastUpdateLayoutInfo.btn.onClick}
            />
          )}
        </div>
      </div>
      <div
        className="gv-flex-1"
        style={{
          minWidth: 0, // This is used to ensure that the flex item can shrink properly and not overflow its container.
        }}
      >
        <img
          src={
            isImgLoading || !imgUrl ? '/assets/mwp/site_overview_preview_placeholder.svg' : imgUrl
          }
          alt={siteTitle}
          style={{ width: '100%', objectFit: 'contain' }}
        />
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------

type UpdateDateInfoProps = {
  type: UpdateDateInfoTypeEnum;
  date: Date | undefined;
  isAutoUpdateActivated: boolean;
  isNoUpdates: boolean; // Refering to update in listing, not update status
  isSiteDown: boolean;
};

// ----------------------------------------------------------------------

function UpdateDateInfo({
  type,
  date,
  isAutoUpdateActivated,
  isNoUpdates,
  isSiteDown,
}: UpdateDateInfoProps) {
  // HOOK
  const { translate, currentLang } = useLocales();

  // HELPER FUNCTION
  function showUpdateDate() {
    // 1. Site DOWN - Shows only last update
    // 2. Site UP - Auto update deactivate - Shows none
    // 3. Site UP - No last update - Shows only next update
    // 4. Site UP - Last update is there - Shows both
    // Showing date or not should be determined based on the above order

    if (
      (isSiteDown && type === UpdateDateInfoTypeEnum.NEXT) || // 1.
      !isAutoUpdateActivated || // 2.
      (!isNoUpdates && type === UpdateDateInfoTypeEnum.LAST) // 3.
    ) {
      return false;
    }

    return true;
  }

  return (
    <div className="gv-flex gv-flex-col">
      <p className="gv-text-sm gv-text-bold" style={{ color: 'var(--color-body-on-placeholder)' }}>
        {translate(`dashboard.sites.details.mwp.overview.preview.updateLabel.${type}`)}
      </p>

      <p className="gv-text-sm">
        {date && showUpdateDate()
          ? fShortDateTime(date, { locale: currentLang.value, separatedByComma: true })
          : '-'}
      </p>
    </div>
  );
}
