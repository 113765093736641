export function getUserTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone; // e.g., "Europe/Copenhagen"
}

export function getAllTimeZones(): string[] {
  const ct = require('countries-and-timezones');

  const allTimezones = Object.entries(ct.getAllTimezones()).map(([_, value]: [string, any]) => ({
    continent: value.name.split('/')[0],
    city: value.name.split('/')[1],
    offset: value.utcOffsetStr,
  }));

  const userTimezone = getUserTimezone();

  const parseOffset = (offset: string): number => {
    const [sign, hours, minutes] = offset.match(/([+-])(\d{2}):(\d{2})/)!.slice(1);
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return sign === '+' ? totalMinutes : -totalMinutes;
  };

  return allTimezones
    .sort((a, b) => {
      const aOffset = parseOffset(a.offset);
      const bOffset = parseOffset(b.offset);

      // 1. User's timezone goes to the top
      if (`${a.continent}/${a.city}` === userTimezone) return -1;
      if (`${b.continent}/${b.city}` === userTimezone) return 1;

      // 2. Europe timezones come after user's timezone, sorted by offset
      if (a.continent === 'Europe' && b.continent !== 'Europe') return -1;
      if (b.continent === 'Europe' && a.continent !== 'Europe') return 1;

      // 3. If both are Europe, sort by offset
      if (a.continent === 'Europe' && b.continent === 'Europe') return aOffset - bOffset;

      // 4. For the rest of the world, sort by offset
      return aOffset - bOffset;
    })
    .map((timezone) => `${timezone.continent}, ${timezone.city}, GMT${timezone.offset}`);
}
