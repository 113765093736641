// @types
import { MWPAdvancedLoginProtectionStatusEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// components
import Badge from 'src/components/gravity/Badge';
import Indicator from 'src/components/gravity/Indicator';

// ----------------------------------------------------------------------

type Props = {
  iconName: string;
  title: string;
  description: string;
  currentPhpVersion?: number; // Only for PHP version option (WP.one)
  currentAlpStatus?: MWPAdvancedLoginProtectionStatusEnum; // Only for ALP option (mWP)
  openInNewTab?: boolean;
  onClick: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AdministrationOption({
  iconName,
  title,
  description,
  currentPhpVersion,
  currentAlpStatus,
  openInNewTab,
  onClick,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div
      className={`gv-content-container gv-p-md gv-flex-row-md gv-items-center gv-span-${
        isMobile ? 2 : 1
      }`}
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      <gv-icon src={`/src/icons/${iconName}.svg`} />
      <div className="gv-flex-1">
        {currentAlpStatus !== undefined && (
          <div className="gv-flex gv-items-center" style={{ gap: '4px' }}>
            <Indicator
              type={
                currentAlpStatus === MWPAdvancedLoginProtectionStatusEnum.ACTIVE
                  ? 'success'
                  : 'default'
              }
            />
            <p
              className="gv-caption-lg gv-text-bold"
              style={{
                color: `var(--color-${
                  currentAlpStatus === MWPAdvancedLoginProtectionStatusEnum.ACTIVE
                    ? 'signal-success'
                    : 'body-on-alternative'
                })`,
              }}
            >
              {translate(
                `dashboard.sites.details.mwp.settings.siteAdministration.loginProtection.status.${currentAlpStatus}`
              )}
            </p>
          </div>
        )}

        <div
          className={`gv-flex gv-flex-col gv-tab-flex-row gv-items-start gv-tab-items-center`}
          style={{
            gap: isMobile ? 0 : '8px',
          }}
        >
          <p className="gv-text-lg gv-text-bold">{translate(title)}</p>

          {currentPhpVersion && (
            <Badge
              type="generic"
              text={`PHP ${currentPhpVersion.toFixed(1)}`}
              style={{ minWidth: '64px' }}
            />
          )}
        </div>
        <p className="gv-caption-lg gv-text-on-alternative">{translate(description)}</p>
      </div>
      <gv-icon src={`/src/icons/${openInNewTab ? 'open_in_new' : 'arrow_forward'}.svg`} />
    </div>
  );
}
