import { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import PageLayout from './PageLayout';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  title: string;
  noPadding?: boolean; // CheckEmail - 403 - 404
  fullWidth?: boolean; // Login
  focus?: boolean; // Migration
  nested?: boolean; // Nested page will not have min height since the outer page already handled this
  meta?: ReactNode;
  extraClass?: string;
};

// ----------------------------------------------------------------------

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', noPadding, fullWidth, focus, nested, meta, extraClass }, ref) => {
    // HOOK
    const { translate } = useLocales();

    return (
      <>
        <Helmet>
          <title>{`${translate(title)} | WP.one`}</title>
          {meta}
        </Helmet>

        <div className="gv-activated">
          {fullWidth ? (
            <div ref={ref}>{children}</div> // Haven't converted to gravity => haven't checked
          ) : (
            <PageLayout
              ref={ref}
              noPadding={noPadding}
              focus={focus}
              nested={nested}
              extraClass={extraClass}
            >
              {children}
            </PageLayout>
          )}
        </div>
      </>
    );
  }
);

export default Page;
