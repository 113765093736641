import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
// @types
import { K8sMetrics, PhpMetrics, MetricsResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime } from 'src/utils/format';
// components
import { Select } from 'src/components/gravity/form';

// ----------------------------------------------------------------------

type Props = {
  data: MetricsResponse;
};

// ----------------------------------------------------------------------

export default function MetricsLineGraph({ data }: Props) {
  // HOOK
  const { translate, currentLang } = useLocales();

  // STATE
  const [selectedHours, setSelectedHours] = useState<number>(1);
  const [filteredK8s, setFilteredK8s] = useState<K8sMetrics[]>([]);
  const [filteredPHP, setFilteredPHP] = useState<PhpMetrics[]>([]);

  // CHART
  const phpSeries = [
    {
      name: translate('wponesupport.sites.details.statistics.metrics.chart.phpWorkers.tooltip'),
      data: filteredPHP.map((item) => ({
        x: new Date(item.collectedAt).getTime(),
        y: item.totalProcesses,
      })),
    },
  ];

  const cpuSeries = [
    {
      name: translate('wponesupport.sites.details.statistics.metrics.chart.cpuUsage.tooltip'),
      data: filteredK8s.map((item) => ({
        x: new Date(item.collectedAt).getTime(),
        y: parseFloat(item.cpuUsage) * 100,
      })),
    },
  ];

  const memorySeries = [
    {
      name: translate('wponesupport.sites.details.statistics.metrics.chart.memoryUsage.tooltip'),
      data: filteredK8s.map((item) => ({
        x: new Date(item.collectedAt).getTime(),
        y: parseInt(item.memoryUsage, 10) / (1024 * 1024),
      })),
    },
  ];

  const phpOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      text: translate('wponesupport.sites.details.statistics.metrics.chart.phpWorkers.title'),
      align: 'center',
    },
    colors: ['var(--color-primary)'],
    xaxis: {
      type: 'datetime' as 'datetime',
      tooltip: {
        enabled: false,
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
      labels: {
        formatter: function (val: string) {
          return fShortDateTime(val, { locale: currentLang.value });
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
    },
    markers: {
      size: 5,
      strokeColors: '#fff',
      strokeWidth: 2,
      fillOpacity: 1,
    },
    tooltip: {
      x: {
        formatter: function (val: number) {
          return fShortDateTime(val, { locale: currentLang.value });
        },
      },
      style: {
        fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 50,
      },
    },
  };

  const cpuOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      text: translate('wponesupport.sites.details.statistics.metrics.chart.cpuUsage.title'),
      align: 'center',
    },
    colors: ['var(--color-primary)'],
    xaxis: {
      type: 'datetime' as 'datetime',
      tooltip: {
        enabled: false,
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
      labels: {
        formatter: function (val: string) {
          return fShortDateTime(val, { locale: currentLang.value });
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(1);
        },
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
    },
    markers: {
      size: 5,
      strokeColors: '#fff',
      strokeWidth: 2,
      fillOpacity: 1,
    },
    tooltip: {
      style: {
        fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
      },
      x: {
        formatter: function (val: number) {
          return fShortDateTime(val, { locale: currentLang.value });
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 50,
      },
    },
  };

  const memoryOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      text: translate('wponesupport.sites.details.statistics.metrics.chart.memoryUsage.title'),
      align: 'center',
    },
    colors: ['var(--color-primary)'],
    xaxis: {
      type: 'datetime' as 'datetime',
      tooltip: {
        enabled: false,
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
      labels: {
        formatter: function (val: string) {
          return fShortDateTime(val, { locale: currentLang.value });
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
    },
    markers: {
      size: 5,
      strokeColors: '#fff',
      strokeWidth: 2,
      fillOpacity: 1,
    },
    tooltip: {
      style: {
        fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
      },
      x: {
        formatter: function (val: number) {
          return fShortDateTime(val, { locale: currentLang.value });
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        left: 50,
      },
    },
  };

  // VAR
  const timeRangeOptions = [
    {
      label: 'wponesupport.sites.details.statistics.metrics.timeRange.options.lastHour',
      value: 1,
    },
    {
      label: 'wponesupport.sites.details.statistics.metrics.timeRange.options.last6Hours',
      value: 6,
    },
    {
      label: 'wponesupport.sites.details.statistics.metrics.timeRange.options.last24Hours',
      value: 24,
    },
    {
      label: 'wponesupport.sites.details.statistics.metrics.timeRange.options.last7Days',
      value: 24 * 7,
    },
  ];

  //
  // Filter the metrics based on the selected time range.
  useEffect(() => {
    const now = new Date();
    const threshold = new Date(now.getTime() - selectedHours * 60 * 60 * 1000);
    const filteredK8sData = data.k8s.filter((item) => new Date(item.collectedAt) >= threshold);
    const filteredPHPData = data.php.filter((item) => new Date(item.collectedAt) >= threshold);
    setFilteredK8s(filteredK8sData);
    setFilteredPHP(filteredPHPData);
  }, [selectedHours, data]);

  return (
    <div className="gv-flex-column-md gv-flex-1">
      <div style={{ maxWidth: 200 }}>
        <Select
          label={translate('wponesupport.sites.details.statistics.metrics.timeRange.label')}
          labelId="time-range"
          value={selectedHours}
          onChange={(e) => setSelectedHours(parseInt(e.target.value, 10))}
          options={timeRangeOptions.map((range) => ({
            value: range.value,
            label: translate(range.label),
          }))}
          condensed
        />
      </div>

      {filteredPHP.length > 0 && (
        <Chart options={phpOptions} series={phpSeries} type="line" height={350} />
      )}

      {filteredK8s.length > 0 && (
        <>
          <Chart options={cpuOptions} series={cpuSeries} type="line" height={350} />
          <Chart options={memoryOptions} series={memorySeries} type="line" height={350} />
        </>
      )}
    </div>
  );
}
