// redux
import { useSelector } from 'src/redux/store';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { SiteStatusEnum } from 'src/@types/site';
import { AccessTypeEnum, UserRoleEnum } from 'src/@types/user';
// hooks
import useHighestUserRole from 'src/hooks/useHighestUserRole';
import useLocales from 'src/hooks/useLocales';
// utils
import { isHostnetCustomer } from 'src/utils/check';
//
import AdministrationOption from './AdministrationOption';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
  onOpenUpdatePHPVersionDialog: VoidFunction;
  onOpenResetSiteDialog: VoidFunction;
  onOpenDeleteSiteDialog: VoidFunction;
  onOpenReactivateSiteDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AdministrationBlock({
  site,
  onOpenUpdatePHPVersionDialog,
  onOpenResetSiteDialog,
  onOpenDeleteSiteDialog,
  onOpenReactivateSiteDialog,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const { highestUserRole, isHighestUserRoleLoading } = useHighestUserRole();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const administrationOptionDisplayInfo = displayAdministrationOptionInfo();

  // HELPER FUNCTION
  function displayAdministrationOptionInfo() {
    let optionArr: {
      id: string;
      iconName: string;
      title: string;
      description: string;
      currentPhpVersion?: number;
      action: VoidFunction;
    }[] = [
      {
        id: 'update_php',
        iconName: 'php',
        title: 'wpone.sites.details.settings.siteAdministration.changePhp.title',
        description: 'wpone.sites.details.settings.siteAdministration.changePhp.description',
        currentPhpVersion: site.php_version,
        action: onOpenUpdatePHPVersionDialog,
      },
    ];

    if (user && !isHighestUserRoleLoading) {
      const isNonCustomer =
        user.role === UserRoleEnum.support || user.role === UserRoleEnum.serviceAccount;
      const isCustomerDeveloper =
        user.role === UserRoleEnum.customer && highestUserRole === AccessTypeEnum.developer;
      const isCustomerOwner =
        user.role === UserRoleEnum.customer && highestUserRole === AccessTypeEnum.owner;

      // Support, SA, and Customer as non-Developer can RESET an active site
      if ((isNonCustomer || !isCustomerDeveloper) && site.state === SiteStatusEnum.active) {
        optionArr.push({
          id: 'reset_site',
          iconName: 'replay',
          title: 'wpone.sites.details.settings.siteAdministration.resetSite.title',
          description: 'wpone.sites.details.settings.siteAdministration.resetSite.description',
          action: onOpenResetSiteDialog,
        });
      }

      // Support and SA can DELETE site with any state
      // Customer as Owner from brands other than Hostnet can DELETE an active site
      if (
        isNonCustomer ||
        (isCustomerOwner && site.state === SiteStatusEnum.active && !isHostnetCustomer(user))
      ) {
        optionArr.push({
          id: 'delete_site',
          iconName: 'delete',
          title: 'wpone.sites.details.settings.siteAdministration.deleteSite.title',
          description: 'wpone.sites.details.settings.siteAdministration.deleteSite.description',
          action: onOpenDeleteSiteDialog,
        });
      }

      // Support and SA can REACTIVATE a suspended site
      if (isNonCustomer && site.state === SiteStatusEnum.suspended) {
        optionArr.push({
          id: 'restart_site',
          iconName: 'restart_alt',
          title: 'wponesupport.sites.details.settings.siteAdministration.reactivateSite.title',
          description:
            'wponesupport.sites.details.settings.siteAdministration.reactivateSite.description',
          action: onOpenReactivateSiteDialog,
        });
      }
    }

    return optionArr;
  }

  return (
    <div className="gv-flex-column-md">
      <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
        <p className="gv-text-lg gv-text-bold">
          {translate('wpone.sites.details.settings.siteAdministration.title')}
        </p>
        <p className="gv-text-sm">
          {translate('wpone.sites.details.settings.siteAdministration.description')}
        </p>
      </div>

      <div className="gv-grid gv-gap-md gv-grid-cols-2">
        {administrationOptionDisplayInfo.map((option, index) => (
          <AdministrationOption
            key={option.id}
            iconName={option.iconName}
            title={option.title}
            description={option.description}
            currentPhpVersion={option.currentPhpVersion}
            onClick={option.action}
          />
        ))}
      </div>
    </div>
  );
}
