import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// @types
import { SiteState, SiteColumnsEnum, UpdateVulnerabilityRow, SiteColumn } from 'src/@types/site';
// constants
import { SITE_COLUMN } from 'src/config';

// ----------------------------------------------------------------------

const initialState: SiteState = {
  // Sites table customization
  selectedCols: Array.from(SITE_COLUMN).filter(
    (col) =>
      col.id === SiteColumnsEnum.diskusage ||
      col.id === SiteColumnsEnum.plan ||
      col.id === SiteColumnsEnum.performance ||
      col.id === SiteColumnsEnum.lcp
  ),
  // Created sites => Display created site banner
  createdSites: [],
  // Sites with disk usage upgraded => Display successfully/fail banner
  diskUpgradedSites: [],
  // Sites with plan changed => Display upgraded/downgraded successfully/fail banner
  planChangedSites: [],
  // Vulnerabilities that are currently being updated
  updatingVulnsInfo: [],
  // MWP
  // Site performance sub score description
  showSubScoreDescription: false,
  // Site updating
  updatingSites: [],
  // Site deletion banner
  deletedSiteName: undefined,
};

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    // SET INITIAL SELECTED COL
    setInitialCols(state, action: PayloadAction<SiteColumnsEnum[]>) {
      state.selectedCols = action.payload.reduce<SiteColumn[]>((selectedCols, tableHead) => {
        const col = SITE_COLUMN.find((col) => col.id === tableHead);
        if (col) {
          selectedCols.push(col);
        }
        return selectedCols;
      }, []);
    },
    // UPDATE SELECTED COLS
    updateSelectedCols(state, action: PayloadAction<SiteColumn[]>) {
      state.selectedCols = action.payload;
    },
    // ADD NEWLY CREATED SITE
    addCreatedSite(state, action: PayloadAction<string>) {
      state.createdSites = [...state.createdSites, action.payload];
    },
    // REMOVE NEWLY CREATED SITE
    removeCreatedSite(state, action: PayloadAction<string>) {
      state.createdSites = state.createdSites.filter((site) => site !== action.payload);
    },
    // ADD DISK UPGRADED SITE
    addDiskUpgradedSite(
      state,
      action: PayloadAction<{ siteName: string; fromTab: string; isSuccess: boolean }>
    ) {
      state.diskUpgradedSites = [...state.diskUpgradedSites, action.payload];
    },
    // REMOVE DISK UPGRADED SITE
    removeDiskUpgradedSite(state, action: PayloadAction<string>) {
      state.diskUpgradedSites = state.diskUpgradedSites.filter(
        (site) => site.siteName !== action.payload
      );
    },
    // ADD PLAN CHANGED SITE
    addChangedPlanSite(
      state,
      action: PayloadAction<{
        siteName: string;
        fromTab: string;
        isUpgraded: boolean;
        isSuccess: boolean;
      }>
    ) {
      state.planChangedSites = [...state.planChangedSites, action.payload];
    },
    // REMOVE PLAN UPGRADED SITES
    removeChangedPlanSite(state, action: PayloadAction<string>) {
      state.planChangedSites = state.planChangedSites.filter(
        (site) => site.siteName !== action.payload
      );
    },
    // ADD SITE UPDATING VULNS
    addUpdatingVulns(
      state,
      action: PayloadAction<{ siteName: string; newUpdatingVulns: UpdateVulnerabilityRow[] }>
    ) {
      const { siteName, newUpdatingVulns } = action.payload;

      // If this site doesn't have any updating at the moment
      if (
        state.updatingVulnsInfo.filter((siteInfo) => siteInfo.siteName === siteName).length === 0
      ) {
        state.updatingVulnsInfo = [
          ...state.updatingVulnsInfo,
          { siteName: siteName, updatingVulns: newUpdatingVulns },
        ];
      }
      // If this site already has updates at the moment
      else {
        state.updatingVulnsInfo = state.updatingVulnsInfo.map((siteInfo) => {
          if (siteInfo.siteName === siteName) {
            return {
              ...siteInfo,
              updatingVulns: [...siteInfo.updatingVulns, ...newUpdatingVulns],
            };
          }

          return siteInfo;
        });
      }
    },
    // REMOVE SITE UPDATING VULNS
    removeUpdatingVulns(
      state,
      action: PayloadAction<{ siteName: string; removeUpdatingVulns: UpdateVulnerabilityRow[] }>
    ) {
      state.updatingVulnsInfo = state.updatingVulnsInfo
        .map((siteInfo) => {
          if (siteInfo.siteName === action.payload.siteName) {
            return {
              ...siteInfo,
              updatingVulns: siteInfo.updatingVulns.filter(
                (updatingVuln) =>
                  !action.payload.removeUpdatingVulns.some((vuln) => vuln.id === updatingVuln.id)
              ),
            };
          }

          return siteInfo;
        })
        .filter((siteInfo) => siteInfo.updatingVulns.length > 0);
    },
    // MWP
    // TOGGLE SITE PERFORMANCE SUB SCORE DESCRIPTION
    toggleSubScoreDescription(state) {
      state.showSubScoreDescription = !state.showSubScoreDescription;
    },
    // ADD UPDATING SITE
    addUpdatingSite(state, action: PayloadAction<string>) {
      state.updatingSites = [
        ...state.updatingSites,
        { siteName: action.payload, displayBanner: true },
      ];
    },
    // DISABLE UPDATING SITE BANNER
    disableUpdatingSiteBanner(state, action: PayloadAction<string>) {
      state.updatingSites = state.updatingSites.map((updatingSite) => {
        if (updatingSite.siteName === action.payload) {
          return {
            ...updatingSite,
            displayBanner: false,
          };
        }

        return updatingSite;
      });
    },
    // REMOVE UPDATING SITE
    removeUpdatingSite(state, action: PayloadAction<string>) {
      state.updatingSites = state.updatingSites.filter((site) => site.siteName !== action.payload);
    },
    // TOGGLE SITE DELETION BANNER
    setDeletedSiteName(state, action: PayloadAction<string | undefined>) {
      state.deletedSiteName = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  // Sites table columns
  setInitialCols,
  updateSelectedCols,
  // Created
  addCreatedSite,
  removeCreatedSite,
  // Disk upgraded
  addDiskUpgradedSite,
  removeDiskUpgradedSite,
  // Plan changed
  addChangedPlanSite,
  removeChangedPlanSite,
  // Updating vulnerabilities
  addUpdatingVulns,
  removeUpdatingVulns,
  // MWP
  // Toggle site performance sub score description
  toggleSubScoreDescription,
  // Site updating
  addUpdatingSite,
  disableUpdatingSiteBanner,
  removeUpdatingSite,
  // Toggle site deletion banner
  setDeletedSiteName,
} = slice.actions;
