import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertUserLocaleToLanguageCode } from 'src/utils/convert';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';
import { TextCustomize } from 'src/components/gravity/text';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
  setIsWPCoreUpdateSuccess: Dispatch<SetStateAction<boolean | null>>;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-overlay-light);
  z-index: 1000;
`;

// ----------------------------------------------------------------------

export default function UpdateWPCoreConfirmation({
  site,
  onClose,
  setIsWPCoreUpdateSuccess,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Temp solution, the attempts should be tracked using backend data to prevent user logout/login or manually delete local storage
  const [attempts, setAttempts] = useState<number[]>([]);
  const [reachedMaxUsage, setReachedMaxUsage] = useState<boolean>(false);

  // VAR
  const currentWPCoreVersion = site?.wordpress_details.core.version;
  // Dummy latest version
  const latestWPCoreVersion = '6.7.1';

  // EVENT HANDLER
  const handleUpdateWPCore = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'mwp-update-wp-core',
    });

    setIsLoading(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
        }, 1000);
      });

      const isSuccess = false;
      setIsWPCoreUpdateSuccess(isSuccess);
    } finally {
      setAttempts([...attempts, Date.now()]);
      setIsLoading(false);
      onClose();
    }
  };

  //
  useEffect(() => {
    // User tries to use the feature 6 or more times in less than an hour they will not be allowed to use it again until that hour is up
    const storedAttempts = JSON.parse(localStorage.getItem('updateAttempts') || '[]');
    const now = Date.now();
    const oneHourAgo = now - 60 * 60 * 1000;

    const validAttempts = storedAttempts.filter((attempt: number) => attempt > oneHourAgo);
    setReachedMaxUsage(validAttempts.length >= 6);
    setAttempts(validAttempts);
  }, []);

  useEffect(() => {
    if (attempts.length > 0) {
      localStorage.setItem('updateAttempts', JSON.stringify(attempts));
    } else {
      localStorage.removeItem('updateAttempts');
    }
  }, [attempts]);

  return !reachedMaxUsage ? (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            `dashboard.sites.details.mwp.settings.updateWPCoreDialog.title.${
              currentWPCoreVersion === latestWPCoreVersion ? 'reset' : 'update'
            }`
          )}
        </h2>

        <div className="gv-flex-column-sm">
          <p>
            {translate(
              `dashboard.sites.details.mwp.settings.updateWPCoreDialog.description.${
                currentWPCoreVersion === latestWPCoreVersion ? 'reset' : 'update'
              }`,
              {
                url: name,
                current_version: currentWPCoreVersion,
                latest_version: latestWPCoreVersion,
              }
            )}
          </p>

          <Alert
            type="info"
            text={
              <TextCustomize
                text={translate(
                  'dashboard.sites.details.mwp.settings.updateWPCoreDialog.infoBanner'
                )}
                textClass="gv-text-sm"
                linkAction={[
                  () =>
                    window.open(
                      `https://help.one.com/hc/${convertUserLocaleToLanguageCode(
                        user?.locale
                      )}/articles/115005593925`,
                      '_blank'
                    ),
                  () =>
                    window.open(
                      `https://help.one.com/hc/${convertUserLocaleToLanguageCode(
                        user?.locale
                      )}/articles/115005593645`,
                      '_blank'
                    ),
                ]}
                linkStyle={{
                  cursor: 'pointer',
                  fontWeight: 600,
                }}
              />
            }
          />
        </div>

        {isLoading && (
          <Overlay>
            <gv-loader class="gv-mode-condensed" src="/src/loaders/spinner.svg" />
            <p
              className="gv-text-center"
              style={{
                maxWidth: '181px',
              }}
            >
              {translate('dashboard.sites.details.mwp.settings.updateWPCoreDialog.loadingText')}
            </p>
          </Overlay>
        )}
      </div>

      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          text={translate('dashboard.sites.details.mwp.settings.updateWPCoreDialog.action')}
          onClick={handleUpdateWPCore}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  ) : (
    <MaxUsageReachedModal onClose={onClose} />
  );
}

// ----------------------------------------------------------------------

type MaxUsageReachedModalProps = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

function MaxUsageReachedModal({ onClose }: MaxUsageReachedModalProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'dashboard.sites.details.mwp.settings.updateWPCoreDialog.maxUsageReachedModal.title'
          )}
        </h2>

        <p>
          {translate(
            'dashboard.sites.details.mwp.settings.updateWPCoreDialog.maxUsageReachedModal.description'
          )}
        </p>
      </div>

      <Button
        text={translate(
          'dashboard.sites.details.mwp.settings.updateWPCoreDialog.maxUsageReachedModal.action'
        )}
        onClick={onClose}
      />
    </>
  );
}
