import { Dispatch, SetStateAction, useState } from 'react';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFRadioGroup } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

enum AlpSettingEnum {
  ALL = 'all',
  ADMIN_ONLY = 'adminOnly',
  NONE = 'none',
}

type FormValuesProps = {
  alpSetting: AlpSettingEnum;
};

type Props = {
  onClose: VoidFunction;
  setSaveAlpSettingSucceed: Dispatch<SetStateAction<boolean | null>>;
};

// ----------------------------------------------------------------------

export default function AdvancedLoginProtectionForm({ onClose, setSaveAlpSettingSucceed }: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // FORM
  const defaultValues = {
    alpSetting: AlpSettingEnum.ALL,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { handleSubmit } = methods;

  // EVENT FUNCTION
  const onSubmit = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'mwp-save-advanced-login-settings',
    });

    setIsLoading(true);

    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
        }, 3000);
      });

      const isSuccess = true;
      if (isSuccess) {
        if (isMountedRef.current) {
          setSaveAlpSettingSucceed(isSuccess);
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.toast.success'
            )
          );
        }
      } else {
        if (isMountedRef.current) {
          setSaveAlpSettingSucceed(isSuccess);
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.toast.error'
            ),
            {
              variant: 'alert',
            }
          );
        }
      }
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.title')}
        </h2>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm">
            <p>
              {translate(
                'dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.description'
              )}
            </p>

            <RHFRadioGroup
              radioSpace="md"
              condensed
              name="alpSetting"
              options={Object.values(AlpSettingEnum).map((option) => ({
                label: translate(
                  `dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.options.${option}.label`
                ),
                description: translate(
                  `dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.options.${option}.description`
                ),
                value: option,
              }))}
            />
          </div>
        </FormProvider>
      </div>

      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          onClick={handleSubmit(onSubmit)}
          text={translate(
            'dashboard.sites.details.mwp.settings.advancedLoginProtectionDialog.action'
          )}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
