import { useState } from 'react';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { SiteSettingDetailsProps, SiteSettingsTechnicalDetails } from 'src/@types/site';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
//
import SettingsDetails from '../../settings/SettingsDetails';
import SettingsBlock from '../../settings/SettingsBlock';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function TechnicalDetailsSettingsBlock({ site }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  // VAR
  // Dummy data
  const siteTechnicalDetails: SiteSettingsTechnicalDetails = {
    basic: {
      phpVersion: site.php_version.toString(),
      wordpressVersion: site.wordpress_details.core.version,
      pathToWebspace: site.installation_path,
      siteIpAddress: site.cluster.address,
    },
    advanced: {
      additionalDetails: {
        dataCentreLocation: site.cluster.location,
        outgoingIpAddress: '203.0.113.1',
        http2: 'active',
      },
      phpSettings: {
        showErrors: 'on',
        maxExecutionTime: 300,
        memoryLimit: 128,
        maxPostSize: 30,
        maxInputTime: 60,
        maxFileUploadSize: 128,
      },
      phpExtensions: {
        imagick: 'active',
        gd: 'inactive',
        curl: {
          version: '7.68.0',
          status: 'active',
        },
      },
    },
  };

  const defaultDetails: SiteSettingDetailsProps[] = [
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.default.php',
      value: siteTechnicalDetails.basic.phpVersion,
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.default.wordpress',
      value: siteTechnicalDetails.basic.wordpressVersion,
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.default.webspacePath',
      value: siteTechnicalDetails.basic.pathToWebspace,
      copiable: true,
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.default.ip',
      value: siteTechnicalDetails.basic.siteIpAddress,
      copiable: true,
    },
  ];

  const additionalDetails: SiteSettingDetailsProps[] = [
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.dataCentre',
      value: siteTechnicalDetails.advanced.additionalDetails.dataCentreLocation,
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.ip',
      value: siteTechnicalDetails.advanced.additionalDetails.outgoingIpAddress,
      copiable: true,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.ip',
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.http2',
      value: `dashboard.sites.details.mwp.settings.technicalDetails.infoValue.activeStatus.${siteTechnicalDetails.advanced.additionalDetails.http2}`,
      copiable: true,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.http2',
    },
  ];

  const phpSettings: SiteSettingDetailsProps[] = [
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.showErrors',
      value: `dashboard.sites.details.mwp.settings.technicalDetails.infoValue.onStatus.${siteTechnicalDetails.advanced.phpSettings.showErrors}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.showErrors',
    },
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.maxExecutionTime',
      value: `${siteTechnicalDetails.advanced.phpSettings.maxExecutionTime} ${translate(
        'dashboard.general.unit.seconds'
      )}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.maxExecutionTime',
    },
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.memoryLimit',
      value: `${siteTechnicalDetails.advanced.phpSettings.memoryLimit} ${translate(
        'dashboard.general.unit.mb'
      )}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.memoryLimit',
    },
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.maxInputTime',
      value: `${siteTechnicalDetails.advanced.phpSettings.maxInputTime} ${translate(
        'dashboard.general.unit.seconds'
      )}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.maxInputTime',
    },
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.maxPostSize',
      value: `${siteTechnicalDetails.advanced.phpSettings.maxPostSize} ${translate(
        'dashboard.general.unit.mb'
      )}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.maxPostSize',
    },
    {
      title:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.maxFileUploadSize',
      value: `${siteTechnicalDetails.advanced.phpSettings.maxFileUploadSize} ${translate(
        'dashboard.general.unit.mb'
      )}`,
      tooltip:
        'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.maxFileUploadSize',
    },
  ];

  const phpExtensions: SiteSettingDetailsProps[] = [
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.imagick',
      value: `dashboard.sites.details.mwp.settings.technicalDetails.infoValue.activeStatus.${siteTechnicalDetails.advanced.phpExtensions.imagick}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.imagick',
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.gd',
      value: `dashboard.sites.details.mwp.settings.technicalDetails.infoValue.activeStatus.${siteTechnicalDetails.advanced.phpExtensions.gd}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.gd',
    },
    {
      title: 'dashboard.sites.details.mwp.settings.technicalDetails.infoLabel.additional.cURL',
      value: `${siteTechnicalDetails.advanced.phpExtensions.curl.version} + ${translate(
        `dashboard.sites.details.mwp.settings.technicalDetails.infoValue.activeStatus.${siteTechnicalDetails.advanced.phpExtensions.curl.status}`
      )}`,
      tooltip: 'dashboard.sites.details.mwp.settings.technicalDetails.infoTooltip.cURL',
    },
  ];

  return (
    <SettingsBlock
      title="dashboard.sites.details.mwp.settings.technicalDetails.title"
      description="dashboard.sites.details.mwp.settings.technicalDetails.description"
      content={
        <div className="gv-flex-column-md">
          <DetailsSection details={defaultDetails} />
          <div className="gv-flex gv-justify-start">
            <div
              className="gv-flex gv-items-center"
              onClick={() => setShowMoreDetails(!showMoreDetails)}
              style={{
                gap: '4px',
                color: 'var(--color-state-form-active)',
                cursor: 'pointer',
                float: 'right',
              }}
            >
              <p className="gv-text-sm gv-text-bold">
                {translate(
                  `dashboard.sites.details.mwp.settings.technicalDetails.action.${
                    showMoreDetails ? 'showLessDetails' : 'showMoreDetails'
                  }`
                )}
              </p>
              <gv-icon src={`/src/icons/keyboard_arrow_${showMoreDetails ? 'up' : 'down'}.svg`} />
            </div>
          </div>
          {showMoreDetails && (
            <>
              <DetailsSection
                title="dashboard.sites.details.mwp.settings.technicalDetails.infoSectionLabel.addtionalDetails"
                details={additionalDetails}
              />
              <DetailsSection
                title="dashboard.sites.details.mwp.settings.technicalDetails.infoSectionLabel.phpSettings"
                details={phpSettings}
              />
              <DetailsSection
                title="dashboard.sites.details.mwp.settings.technicalDetails.infoSectionLabel.phpExtensions"
                details={phpExtensions}
              />
            </>
          )}
        </div>
      }
    />
  );
}

// ----------------------------------------------------------------------

type DetailsSectionProps = {
  title?: string;
  details: SiteSettingDetailsProps[];
};

// ----------------------------------------------------------------------

const DetailsSection = ({ title, details }: DetailsSectionProps) => {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex-column-sm">
      {title && <p className="gv-text-sm gv-text-bold">{translate(title)}</p>}
      <div
        className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-desk-grid-cols-4`}
        style={{
          gridRowGap: '16px',
          gridColumnGap: '24px',
        }}
      >
        {details.map((detail, index) => (
          <SettingsDetails
            key={index}
            title={detail.title}
            value={detail.value}
            copiable={detail.copiable}
            tooltip={detail.tooltip}
          />
        ))}
      </div>
    </div>
  );
};
