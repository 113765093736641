import { useParams } from 'react-router';
// redux
import { useGetCurrentSiteMetricsQuery } from 'src/redux/api/metricsApi';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { PageSection } from 'src/components/gravity/page';
//
import MetricsLineGraph from './MetricsLineGraph';

// ----------------------------------------------------------------------

export default function MetricsBlock() {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const { data, isFetching } = useGetCurrentSiteMetricsQuery({
    cluster: cluster as string,
    namespace: namespace as string,
  });

  return (
    <>
      {data && !isFetching && (
        <PageSection
          title={translate('wponesupport.sites.details.statistics.metrics.title')}
          description={translate('wponesupport.sites.details.statistics.metrics.description')}
        >
          <MetricsLineGraph data={data.data} />
        </PageSection>
      )}
    </>
  );
}
