// @types
import { SiteSettingDetailsProps } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import Tooltip from 'src/components/gravity/Tooltip';
import IconWrapper from 'src/components/gravity/IconWrapper';

// ----------------------------------------------------------------------

export default function SettingsDetails({
  title,
  tooltip,
  value,
  copiable = false,
  password,
}: SiteSettingDetailsProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-flex gv-flex-col">
      <div
        className="gv-flex gv-flex-row gv-items-center"
        style={{ gap: '4px', color: 'var(--color-body-on-placeholder)' }}
      >
        <p className="gv-text-sm gv-text-bold">{translate(title)}</p>
        {tooltip && (
          <Tooltip
            tooltip={translate(tooltip)}
            width={216}
            position="top-left"
            arrow="bottom-left"
            gap={{
              x: -6,
              y: -4,
            }}
          >
            <gv-icon src="/src/icons/info.svg" size="small" />
          </Tooltip>
        )}
      </div>
      <div className="gv-flex-row-sm gv-items-center">
        {password ? (
          <>
            <div
              className={`gv-flex-row-sm gv-items-center${
                !password.isHidden ? ' gv-text-truncate' : ''
              }`}
              style={{
                overflow: 'hidden',
              }}
            >
              <p
                className={`gv-text-sm${!password.isHidden ? ' gv-text-truncate' : ''}`}
                style={{
                  overflow: 'hidden',
                }}
              >
                {password.isHidden ? value.replace(/./g, '*') : value}
              </p>
              <IconWrapper onClick={password.onShow} style={{ cursor: 'pointer' }}>
                <gv-icon
                  src={`/src/icons/${password.isHidden ? 'visibility' : 'visibility_off'}.svg`}
                />
              </IconWrapper>
            </div>
            <IconWrapper onClick={password.onCopy} style={{ cursor: 'pointer' }}>
              <gv-icon src="/src/icons/content_copy.svg" />
            </IconWrapper>
          </>
        ) : (
          <>
            <p className="gv-text-sm gv-text-truncate">{translate(value)}</p>
            {copiable && (
              <IconWrapper
                onClick={() => {
                  navigator.clipboard.writeText(value);
                  displayToast(translate('wpone.general.action.copied'));
                }}
                style={{ cursor: 'pointer' }}
              >
                <gv-icon src="/src/icons/content_copy.svg" />
              </IconWrapper>
            )}
          </>
        )}
      </div>
    </div>
  );
}
