import { ReactNode } from 'react';
//
import { AccessTypeEnum, UserRoleEnum } from './user';
import { SiteBrandEnum } from './site';

// ----------------------------------------------------------------------

export type LayoutState = {
  showNewFeatureModal: boolean; // Keep track of whether or not this is user log in (only after login this can be set to true, else will always be false)
  lastReadFeatureVersion: number; // Keep track of last read feature version for this user (if the modal is not showed in the first place, it will always be default - 1.0)
  displayFeatureArr: FeatureReleaseDetails[];
  //
  bannerHeight: number;
  footerHeight: number;
  sidebarHighlightActivePage: boolean; // Set to false whenever the current active page need to be UNhighlighted in the sidebar
  tabConfig: PageDetailsTabConfig | undefined; // Set whenever navigate to a page with sidebar
  siteWebspaceToken: string | undefined;
  //
  showActionsInProgressBanner: boolean;
  //
  enableScrollLock: boolean;
  overflowPadding: number; // !== 0 when enabling scroll lock
  //
  siteBrand: SiteBrandEnum | undefined;
  //
  globalSearchHistory: GlobalSearchOptionProps[];
};

export type FeatureReleaseDetails = {
  name: string;
  version: number;
  releaseDate: Date; // Year - Month (index 0 - 11) - Day
  text: {
    title: string;
    description: string;
  };
};

export type NavLinkDetails = {
  title: string;
  path: string;
  icon: string;
};

export type TabNavLinkDetails = {
  title: string;
  tabName: string;
  icon: string;
};

export type PageDetailsTabConfig = {
  primaryPathname: string;
  title: string;
  navConfig: TabNavLinkDetails[] | SiteTabNavLinkDetails[];
};

export type SiteTabNavLinkDetails = TabNavLinkDetails & {
  restrictedUserRoles?: UserRoleEnum[];
  restrictedSiteRoles?: AccessTypeEnum[];
  restrictedForHostnetCustomer?: boolean;
};

export type GlobalSearchOptionProps = {
  id: string;
  cluster?: string;
  namespace?: string;
  group: GlobalSearchGroupEnum;
  // Site hostname, additional domain, or domain
  name: string;
  // Site domain in case the name is additional domain
  fromSite?: string;
};

export enum GlobalSearchGroupEnum {
  ALL = 'all',
  SITES = 'sites',
  DOMAINS = 'domains',
}

export type TabDetails = {
  tabName: string;
  component: ReactNode;
};

export type TableHeader = {
  id: string;
  label: string; // Empty label = Empty string
  sort?: boolean;
  tooltip?: string;
};

export enum ProgressStepStatusEnum {
  WAITING = 'waiting',
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
}
