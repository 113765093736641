import { Dispatch, SetStateAction } from 'react';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import RepairWordpressCoreConfirmation from './RepairWordpressCoreConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  setIsRepairWordpressCoreSucceed: Dispatch<SetStateAction<boolean | null>>;
};

// ----------------------------------------------------------------------

export default function RepairWordpressCoreDialog({
  open,
  onClose,
  setIsRepairWordpressCoreSucceed,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <RepairWordpressCoreConfirmation
          onClose={onClose}
          setIsRepairWordpressCoreSucceed={setIsRepairWordpressCoreSucceed}
        />
      }
    />
  );
}
