import { useNavigate, useParams } from 'react-router';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import {
  PerformanceScoreGeneralInfo,
  LighthouseStatusEnum,
  LighthouseScoreEnum,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertLighthouseValueToStatus, getLighthouseStats } from 'src/utils/convert';
import getWpRocketAndRankMathStatus from 'src/utils/getWpRocketAndRankMathInfo';
// components
import DonutChart from 'src/components/gravity/DonutChart';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
  cardInfo: PerformanceScoreGeneralInfo;
};

// ----------------------------------------------------------------------

export default function PerformanceCard({ site, cardInfo }: Props) {
  const { name, cluster, namespace } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // VAR
  const stats = getLighthouseStats(cardInfo.type, site.lighthouse_statistics);

  const lightHouseStatus = convertLighthouseValueToStatus(cardInfo.type, stats.currentValue);

  // EVENT FUNCTION
  function handleNavigateToPerformanceTab(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    // Prevent the default anchor click behavior
    e.preventDefault();

    navigate(`/sites/performance/${cluster}/${namespace}/${name}`);

    // Delay the smooth scrolling to ensure the navigation has completed
    setTimeout(() => {
      // Get the target element that the anchor link points to
      const target = document.getElementById(cardInfo.type);
      // Get the target element for site details performance page
      const pageTarget = document.getElementById('site_performance');

      if (target && pageTarget) {
        const targetPosition = target.getBoundingClientRect().top - pageTarget.offsetTop;

        window.scrollTo({
          top: targetPosition + window.scrollY,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  return (
    <div className="gv-card" style={{ background: 'var(--color-surface-bright)' }}>
      <div className="gv-card-content">
        <div className="gv-flex-column-md gv-items-start">
          <DonutChart
            type={
              lightHouseStatus === LighthouseStatusEnum.unknown
                ? undefined
                : lightHouseStatus === LighthouseStatusEnum.good
                ? 'success'
                : lightHouseStatus
            }
            value={stats.currentValue !== undefined ? stats.currentValue * 100 : 0}
          />
          <div className="gv-flex gv-flex-col">
            <p className="gv-text-sm gv-text-bold">
              {translate(
                `dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.${cardInfo.type}.title`
              )}
            </p>
            <p className="gv-text-sm gv-text-on-alternative">
              {translate(cardInfo.description[lightHouseStatus])}
            </p>
          </div>
          {(lightHouseStatus === LighthouseStatusEnum.warning ||
            lightHouseStatus === LighthouseStatusEnum.alert) && (
            <a href="/#" className="gv-action" onClick={handleNavigateToPerformanceTab}>
              <div className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
                <span
                  className="gv-text-sm gv-text-bold"
                  style={{ color: 'var(--color-state-form-on)' }}
                >
                  {translate(
                    `dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.action.${
                      (cardInfo.type === LighthouseScoreEnum.PERFORMANCE &&
                        !getWpRocketAndRankMathStatus(site, cardInfo.type)) ||
                      (cardInfo.type === LighthouseScoreEnum.SEO &&
                        !getWpRocketAndRankMathStatus(site, cardInfo.type))
                        ? 'improveNow'
                        : 'seeDetails'
                    }`
                  )}
                </span>
                <gv-icon src="/src/icons/arrow_forward.svg" />
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
