import { Dispatch, SetStateAction } from 'react';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import AdvancedLoginProtectionForm from './AdvancedLoginProtectionForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  setSaveAlpSettingSucceed: Dispatch<SetStateAction<boolean | null>>;
};

// ----------------------------------------------------------------------

export default function AdvancedLoginProtectionDialog({
  open,
  onClose,
  setSaveAlpSettingSucceed,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <AdvancedLoginProtectionForm
          onClose={onClose}
          setSaveAlpSettingSucceed={setSaveAlpSettingSucceed}
        />
      }
    />
  );
}
