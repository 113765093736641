import { PaginatedK8sMetricsResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// redux
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const metricsApi = createApi({
  reducerPath: 'metricsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/k8s-metrics' }),
  tagTypes: ['metricsApi'],
  endpoints: (builder) => ({
    //GET CURRENT INSTALL OPTIONS
    getCurrentSiteMetrics: builder.query<PaginatedK8sMetricsResponse, { cluster: string; namespace: string }>({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}`,
        method: 'get',
      }),
    }),
  }),
});

export const { useGetCurrentSiteMetricsQuery } = metricsApi;
