import { useNavigate, useParams } from 'react-router';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { PerformanceScoreGeneralInfo, LighthouseScoreEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { convertLighthouseValueToStatus, getLighthouseStats } from 'src/utils/convert';
// components
import { PageSection } from 'src/components/gravity/page';
//
import PerformanceCard from './PerformanceCard';

// ----------------------------------------------------------------------

const PERFORMANCE_CARDS_INFO: {
  performance: PerformanceScoreGeneralInfo;
  seo: PerformanceScoreGeneralInfo;
  accessibility: PerformanceScoreGeneralInfo;
  bestPractices: PerformanceScoreGeneralInfo;
} = {
  performance: {
    type: LighthouseScoreEnum.PERFORMANCE,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.alert',
    },
  },
  seo: {
    type: LighthouseScoreEnum.SEO,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.alert',
    },
  },
  accessibility: {
    type: LighthouseScoreEnum.ACCESSIBILITY,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.alert',
    },
  },
  bestPractices: {
    type: LighthouseScoreEnum.BEST_PRACTICES,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.alert',
    },
  },
};

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function PerformanceMonitorBlock({ site }: Props) {
  const { name, namespace, cluster } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // HELPER FUNCTION
  function getPerformanceDescription() {
    const counts = {
      unknown: 0,
      good: 0,
      warning: 0,
      alert: 0,
    };

    Object.values(LighthouseScoreEnum).forEach((type) => {
      const stats = getLighthouseStats(type, site.lighthouse_statistics);
      const lightHouseStatus = convertLighthouseValueToStatus(type, stats.currentValue);

      counts[lightHouseStatus]++;
    });

    switch (true) {
      case counts.unknown > 0:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.unknown';
      case counts.alert > 0:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.alert';
      case counts.warning > 0:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.warning';
      default:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.good';
    }
  }

  return (
    <PageSection
      title={translate('dashboard.sites.details.mwp.overview.performanceMonitor.title')}
      description={translate(getPerformanceDescription())}
      action={{
        text: translate('dashboard.sites.details.mwp.overview.performanceMonitor.action'),
        onClick: () => navigate(`/sites/performance/${cluster}/${namespace}/${name}`),
      }}
    >
      <div
        className={`gv-grid gv-grid-cols-${
          isMobile ? 1 : 2
        } gv-desk-grid-cols-4 gv-gap-lg gv-w-full`}
      >
        {[
          LighthouseScoreEnum.PERFORMANCE,
          LighthouseScoreEnum.SEO,
          LighthouseScoreEnum.ACCESSIBILITY,
          LighthouseScoreEnum.BEST_PRACTICES,
        ].map((type) => (
          <PerformanceCard key={type} site={site} cardInfo={PERFORMANCE_CARDS_INFO[type]} />
        ))}
      </div>
    </PageSection>
  );
}
