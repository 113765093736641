import { forwardRef, ReactNode } from 'react';
// config
import { HEADER } from 'src/config';
// redux
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  noPadding?: boolean; // CheckEmail - 403 - 404
  focus?: boolean;
  nested?: boolean; // Nested page will not have min height since the outer page already handled this
  extraClass?: string;
};

// ----------------------------------------------------------------------

const PageLayout = forwardRef<HTMLDivElement, Props>(
  ({ children, noPadding, focus, nested, extraClass }, ref) => {
    // STATE
    const { footerHeight, overflowPadding, bannerHeight } = useSelector((state) => state.layout);

    return (
      <div
        className="gv-flex gv-justify-center"
        style={{
          minHeight: nested
            ? 'auto'
            : `calc(100vh - ${footerHeight + HEADER.HEIGHT + bannerHeight}px)`,
          paddingRight: overflowPadding,
          backgroundColor: 'var(--color-surface-dim)',
        }}
      >
        <div
          ref={ref}
          className={`gv-p-${noPadding ? '0' : 'fluid'} gv-w-full${
            extraClass ? ` ${extraClass}` : ''
          }`}
          style={{
            maxWidth: focus ? 1210 : 1536,
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default PageLayout;
