import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import {
  LighthouseResponse,
  RunLighthouseAuditResponseDto,
} from '@joonasvanhatapio/wp-cloud-backend-types';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------
// Site details - Performance tab (MWP)

export const lighthouseApi = createApi({
  reducerPath: 'lighthouseApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/lighthouse' }),
  tagTypes: ['Lighthouse'],
  endpoints: (builder) => ({
    // GET LIGHTHOUSE HISTORY
    getLighthouseHistory: builder.query<
      LighthouseResponse,
      { cluster: string; namespace: string; page: number }
    >({
      query: ({ cluster, namespace, page }) => {
        const searchParams = new URLSearchParams();

        const now = new Date();
        // Last 12 weeks
        searchParams.append(
          'since',
          new Date(now.setHours(0, 0, 0, 0) - 84 * 24 * 60 * 60 * 1000).toISOString()
        );

        searchParams.append('page', page.toString());

        const searchParamsString = searchParams.toString();

        return {
          url: `/${cluster}/${namespace}?${searchParamsString}`,
          method: 'get',
        };
      },
      providesTags: (result) => (result ? [{ type: 'Lighthouse', id: 'LIGHTHOUSE' }] : []),
    }),
    // RUN AUDIT
    runAudit: builder.mutation<
      RunLighthouseAuditResponseDto,
      { cluster: string; namespace: string }
    >({
      query: ({ cluster, namespace }) => ({
        url: `/${cluster}/${namespace}`,
        method: 'post',
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Lighthouse', id: 'LIGHTHOUSE' }] : []),
    }),
  }),
});

export const { useLazyGetLighthouseHistoryQuery, useRunAuditMutation } = lighthouseApi;
