import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';
// redux
import { useLazyGetLighthouseHistoryQuery } from 'src/redux/api/lighthouseApi';
// @types
import { LighthouseDataItem } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const ChartStyle = styled.div`
  .apexcharts-tooltip-title {
    background: var(--color-gray-200) !important;
  }

  .apexcharts-tooltip-marker::before {
    font-size: 16px;
  }
`;

// ----------------------------------------------------------------------

export default function PerformanceHistoryChart() {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // API
  const [getLighhouseHistory] = useLazyGetLighthouseHistoryQuery();

  // STATE
  const [historyData, setHistoryData] = useState<LighthouseDataItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // VAR
  const parsedApiResponse = isLoading ? [] : filterResult(historyData);

  // CHART
  const chartOptions = {
    chart: {
      type: 'area' as 'area',
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: translate('dashboard.sites.details.mwp.performance.block.performance.chart.tooltip'),
        data: parsedApiResponse.map((item) => ({
          x: item.date,
          y: item.score,
        })),
      },
    ],
    colors: ['var(--color-primary)'],
    xaxis: {
      categories: parsedApiResponse.map((item) => item.date),
      tooltip: {
        enabled: false,
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: function (val: number, index: number) {
          return val.toFixed(0);
        },
        style: {
          fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
        },
      },
    },
    stroke: {
      curve: 'smooth' as 'smooth',
      width: 2,
    },
    tooltip: {
      style: {
        fontFamily: 'PlusJakartaSans, Arial, Verdana, sans-serif',
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        gradientToColors: ['var(--color-primary)'],
        opacityFrom: 0.2,
        opacityTo: 0.1,
      },
    },
  };

  // HELPER FUNCTION
  async function getPerformanceHistoryData() {
    if (!cluster || !namespace) return;

    setIsLoading(true);

    let page = 1;
    const twelveWeeksAgo = new Date();
    twelveWeeksAgo.setHours(0, 0, 0, 0); // Start from midnight
    twelveWeeksAgo.setDate(twelveWeeksAgo.getDate() - 12 * 7); // Subtract 12 weeks

    const validResults: LighthouseDataItem[] = []; // To store valid results

    // Continue fetching next page until one of the following:
    // 1. Request is not successful
    // 2. Current page is already total page
    // 3. One period in the result is from a date that older than 12 weeks ago from today
    while (true) {
      try {
        const response = await getLighhouseHistory({ cluster, namespace, page }).unwrap();

        // Stop if the request is not successful (assumes successful requests resolve)
        if (!response) break;

        const { current, total } = response.window.page;

        // Add valid items from response.data to the results
        const newValidItems = response.data.filter(
          (item) => item.valid.from && new Date(item.valid.from) >= twelveWeeksAgo
        );
        validResults.push(...newValidItems);

        // Stop if any item's `from` date is older than 12 weeks
        const hasOldDate = response.data.some(
          (item) => item.valid.from && new Date(item.valid.from) < twelveWeeksAgo
        );
        if (hasOldDate) break;

        // Stop if current page is the last page
        if (current === total) break;

        // Increment page for the next iteration
        page += 1;
      } catch (error) {
        console.error('Error fetching history:', error);
        break; // Stop the loop on error
      }
    }

    setIsLoading(false);
    setHistoryData(validResults);
  }

  function filterResult(results: LighthouseDataItem[]): {
    score: number;
    date: string;
  }[] {
    const formattedResult: {
      score: number;
      date: Date;
    }[] = results
      .filter((item) => item.valid.from !== null)
      .map((item) => ({
        score: item.performance.score,
        date: new Date(item.valid.from as string),
      }));

    // Sort items by date descending
    formattedResult.sort((a, b) => b.date.getTime() - a.date.getTime());

    const filteredResult: {
      score: number;
      date: Date;
    }[] = [];
    const periods: Set<number> = new Set();

    // Get the current date
    const now = new Date();

    // Only get the latest result in a period
    // Each period is 1 week on desktop, 2 weeks on mobile
    for (const item of formattedResult) {
      const timeDiff = now.getTime() - item.date.getTime();

      const periodIndex = Math.floor(timeDiff / ((isMobile ? 14 : 7) * 24 * 60 * 60 * 1000));

      if (!periods.has(periodIndex)) {
        periods.add(periodIndex);
        filteredResult.push(item);
      }
    }

    // Convert the Map back to an array of results
    return filteredResult
      .map((item) => ({
        score: item.score * 100,
        date: `${item.date.getDate().toString().padStart(2, '0')}/${(item.date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`,
      }))
      .reverse();
  }

  //
  useEffect(() => {
    getPerformanceHistoryData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChartStyle>
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="area"
        height={350}
      />
    </ChartStyle>
  );
}
