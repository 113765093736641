import { Dispatch, SetStateAction } from 'react';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import UpdateWPCoreConfirmation from './UpdateWPCoreConfirmation';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
  open: boolean;
  setIsWPCoreUpdateSuccess: Dispatch<SetStateAction<boolean | null>>;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpdateWPCoreDialog({
  site,
  open,
  setIsWPCoreUpdateSuccess,
  onClose,
}: Props) {
  return (
    <ActionDialog
      useClickOutside={false}
      open={open}
      onClose={onClose}
      children={
        <UpdateWPCoreConfirmation
          site={site}
          setIsWPCoreUpdateSuccess={setIsWPCoreUpdateSuccess}
          onClose={onClose}
        />
      }
    />
  );
}
