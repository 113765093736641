import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import {
  setShowModal,
  toggleActionsInProgressBanner,
  toggleScrollLock,
} from 'src/redux/features/layout';
// hooks
import useToggle from 'src/hooks/useToggle';
import useClickOutsideEffect from 'src/hooks/useClickOutside';
import useResponsive from 'src/hooks/useResponsive';
// config
import { HEADER } from 'src/config';
//
import DashboardHeader from './header/DashboardHeader';
import DashboardDrawer from './drawer/DashboardDrawer';
import TabDrawer from './drawer/TabDrawer';
import DashboardFooter from './footer/DashboardFooter';
import ActionsInProgressBanner from './banner/ActionsInProgressBanner';
import NewFeatureDialog from './new-feature-modal/newFeatureDialog';

// ----------------------------------------------------------------------

// Feature release will be marked by version number
// Default: 1.0
// Migration: 1.1
// Backup: 1.2
// Only display feature that has version number higher than lastReadFeatureVersion field from user object AND release date til today hasn't exceeded 90 days
// lastReadFeatureVersion will be updated (making api call) when any new feature introduction is visible to user + close the dialog

// Example 1: we are currently at 1.2 version, user lastReadFeatureVersion is 1.0 => will show both migration and backup features in the modal
// Now migration introduction is visible in the banner, if user close the modal AFTER navigate to backup intro => make api call to update lastReadFeatureVersion to 1.2
// Next time the user login => show nothing

// Example 2: we are currently at 1.2 version, user lastReadFeatureVersion is 1.0 => will show both migration and backup features in the modal
// Now migration introduction is visible in the banner, if user close the modal RIGHT AWAY (haven't navigated to backup intro) => make api call to update lastReadFeatureVersion to 1.1
// Next time the user login => only show backup feature

// Example 3: we are currently at 1.2 version, user lastReadFeatureVersion is 1.1 => will show only backup features in the modal
// Now backup introduction is visible in the banner, if user close the modal => make api call to update lastReadFeatureVersion to 1.2
// Next time the user login => show nothing

// Example 4: we are currently at 1.2 version, user lastReadFeatureVersion is 1.2 => show nothing

// ----------------------------------------------------------------------

export default function MainLayout() {
  const dispatch = useDispatch();

  const location = useLocation();

  const dashboardDrawerRef = useRef<HTMLDivElement>(null);

  const tabDrawerRef = useRef<HTMLDivElement>(null);

  // STATE
  const { showNewFeatureModal, showActionsInProgressBanner, footerHeight, bannerHeight } =
    useSelector((state) => state.layout);

  const [openDashboardDrawer, setOpenDashboardDrawer] = useState<boolean>(false);
  const [openTabDrawer, setOpenTabDrawer] = useState<boolean>(false);

  const { migrationsInProgress } = useSelector((state) => state.migration);
  const { backupsInProgress } = useSelector((state) => state.backup);

  // HOOK
  const isDesktop = useResponsive('up', 'desktop_min');

  useClickOutsideEffect({
    outsideRefs: [dashboardDrawerRef],
    handleClickingOutside: handleCloseDashboardDrawer,
    shouldListen: openDashboardDrawer,
  });

  useClickOutsideEffect({
    outsideRefs: [tabDrawerRef],
    handleClickingOutside: handleCloseTabDrawer,
    shouldListen: openTabDrawer,
  });

  const { onClose: onCloseNewFeatureDialog } = useToggle();

  // EVENT FUNCTION
  function handleOpenDashboardDrawer() {
    setOpenDashboardDrawer(true);
    dispatch(toggleScrollLock(true));
  }

  function handleCloseDashboardDrawer() {
    setOpenDashboardDrawer(false);
    dispatch(toggleScrollLock(false));
  }

  function handleOpenTabDrawer() {
    setOpenTabDrawer(true);
    dispatch(toggleScrollLock(true));
  }

  function handleCloseTabDrawer() {
    setOpenTabDrawer(false);
    dispatch(toggleScrollLock(false));
  }

  function handleCloseNewFeatureDialog() {
    dispatch(setShowModal(false));
    onCloseNewFeatureDialog();
  }

  //
  useEffect(() => {
    ReactGA.event('login');
    ReactGA.send('pageview');
  }, []);

  useEffect(() => {
    if (migrationsInProgress.length === 0 && backupsInProgress.length === 0) {
      dispatch(toggleActionsInProgressBanner(false));
    }
  }, [dispatch, location, migrationsInProgress, backupsInProgress]);

  return (
    <main>
      <DashboardHeader
        handleOpenDashboardDrawer={handleOpenDashboardDrawer}
        handleOpenTabDrawer={handleOpenTabDrawer}
      />

      {openDashboardDrawer && (
        <DashboardDrawer
          handleCloseDashboardDrawer={handleCloseDashboardDrawer}
          dashboardDrawerRef={dashboardDrawerRef}
        />
      )}

      {!isDesktop && openTabDrawer && (
        <TabDrawer
          handleCloseTabDrawer={handleCloseTabDrawer}
          tabDrawerRef={tabDrawerRef}
          openTabDrawer={openTabDrawer}
        />
      )}

      <NewFeatureDialog open={showNewFeatureModal} onClose={handleCloseNewFeatureDialog} />

      <div
        style={{
          minHeight: `calc(100vh - ${footerHeight + bannerHeight}px)`, // Prevent visual glitch on first load where there is no page content
          paddingTop: `${HEADER.HEIGHT}px`,
        }}
      >
        {showActionsInProgressBanner && <ActionsInProgressBanner />}
        <Outlet />
      </div>
      <DashboardFooter />
    </main>
  );
}
